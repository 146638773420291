<template>
      <td style="width:30%">
        <span style="font-weight:bold">{{ config.name }}</span> (String) {{getDescription(config)}}
      </td>
      <td>
        <Select v-model:value="config_temp.value" style="width: 100%" :options="config_options"> 
        </Select>
        <Button  type="primary" @click=onEdit>
            <EditOutlined /> Edit
        </Button>
    </td>
</template>

<script>
import { Select } from 'ant-design-vue';
import { Button } from 'ant-design-vue';
import { EditOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

export default {
  data() {
    return {
      config_temp: {},
      config_options: []
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  components: { 
    Select,
    Button,
    EditOutlined
  },
  methods: {
    onEdit() {
      console.log(this.config_temp)
      this.$store.submodule_config = this.config_temp.config_type.submodule_config;
      this.$store.submodule_config_name = this.config_temp.name;
      this.$router.push({ name: 'ConfigurationSubmoduleDetails' });
      
    },
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    }

  },
  created() {
    this.config_temp = this.config;
    this.config_options = ref(this.config_temp.config_type.editor_values)
  },
  mounted() {
    console.log("Mounted");
    if (this.$store.submodule_config_name != "") {
      if (this.$store.submodule_config_name == this.config_temp.name) {
        console.log("Mounted ", this.$store.submodule_config);
        this.config_temp.config_type.submodule_config = this.$store.submodule_config;
        this.$store.submodule_config = {};
        this.$store.submodule_config_name = "";
      }
    }
  }
}
</script>

<style scoped>
tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px;
  text-align: left;
}
</style>