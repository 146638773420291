import axios from 'axios'
//import Utils from './Utils';
import store from '../vuex/store.js'
/*
const apiClient = axios.create({
    baseURL: Utils.getBaseURL(),
    withCreadentials: false,
})
*/
export default {
    getAxios(url, data) {
        let result = axios.get(url, data);
        result
            .then(response => {
                if (response.data.plant_info) {
                    let plant_info = response.data.plant_info;
                    store.state.plant_info = plant_info;
                } else {
                    store.state.plant_info = {}
                }
                if ((response.data["login"] !== undefined) && (response.data.login == null)) {
                    // logout, if authorisation key has expired. This will be notified by empty login == null.
                    // An expired auth key will be notified in this way, when api query does not absolutely need an login (for example plant_info in Homescreen)
                    if (store.state.user_data != null) {
                        console.log("############################## logout #####################################")
                        store.dispatch('logout');
                    }
                }
            })
            .catch(error => {
                if (error.response.status == 422) {
                    // logout, if authorisation key has expired. This will be notified by api queries, which needs authorisation.
                    if (store.state.user_data != null) {
                        console.log("############################## logout #####################################")
                        store.dispatch('logout');
                    }
                }
                store.state.plant_info = {}
            })
        return result;
    },
    putAxios(url, data) {
        let result = axios.put(url, data);
        result
            .then(response => {
                if (response.data.plant_info) {
                    let plant_info = response.data.plant_info;
                    store.state.plant_info = plant_info;
                } else {
                    store.state.plant_info = {}
                }
            })
            .catch(error => {
                console.log(error);
                store.state.plant_info = {}
            })
        return result;
    },
    getInfo() {
        console.log("1")
        return axios.get('/info');
    },
    login(credentials) {
        return axios.post('/login', credentials);
    },
    getModules() {
        return this.getAxios('/modules/list');
    },
    getModuleConfig(module_name) {
        return axios.get('/modules/config/' + module_name);
    },
    saveModuleConfig(module_name, module_config) {
        console.log(module_config);
        return axios.put('/modules/config/' + module_name, module_config);
    },
    getModuleClasses() {
        return axios.get('/modules/classes');
    },
    createNewModule(module_class, module_id) {
        console.log("Create new Module " + module_class + "_" + module_id);
        return axios.put('/modules/new/', { "class": module_class, "id": module_id })
    },
    deleteModule(module_name) {
        console.log("Delete Module: " + module_name);
        return axios.put('/modules/delete/', { "name": module_name })
    },
    startModule(module_name) {
        console.log("Start Module: " + module_name);
        return axios.put('/modules/start/', { "name": module_name })
    },
    stopModule(module_name) {
        console.log("Stop Module: " + module_name);
        return axios.put('/modules/stop/', { "name": module_name })
    },
    reloadModule(module_name) {
        console.log("Reload Module: " + module_name);
        return axios.put('/modules/reload/', { "name": module_name })
    },
    getModuleActions(module_name) {
        return axios.get('/modules/actions/' + module_name);
    },
    sendModuleAction(module_name, action_name, action_param, timeout=2000) {
        return axios.put('/modules/action', { "name": module_name, "action": action_name, "params": action_param}, { "timeout": timeout });
    },
    sendModuleDebugging(module_name, loglevel) {
        return axios.put('/modules/debugging', { "name": module_name, "loglevel": loglevel });
    },
    getModuleVariables() {
        return this.putAxios('/modules/variables', { "modules": store.state.selected_modules_dict });
    },
    putNotificationList(data) {
        return axios.put('/notifications/list', data)
    },
    putNotificationConfirm(data) {
        return axios.put('/notifications/confirm', data)
    },
    getNotificationFilterCriteria(data) {
        return axios.get('/notifications/filter_criteria', data)
    },
    getNotificationCalendar() {
        return axios.get('/notifications/calendar')
    },
    putNotificationCalendar(data) {
        return axios.put('/notifications/calendar', data)
    },
    putNotificationCalendarDataOfDay(data) {
        return axios.put('/notifications/calendar_day', data)
    },    
    putNotificationCalendarUserAction(useraction, userdata) {
        return axios.put('/notifications/calendar_user', {"action": useraction, "data": userdata })
    },    
    getPlantStatusInfo() {
        return this.getAxios('/plantstatus/info');
    },
    putLoggingList(data) {
        return axios.put('/logging/list', data)
    },
    getVersionHistoryDownload() {
        return axios.get('/version_history/download')
    }
}