import axios from 'axios'
import { createStore } from 'vuex'
import ModuleServices from '@/services/ModuleServices.js'
import Utils from '../services/Utils';

export default createStore({
  state: {
    router: "Router",
    module_config: {},
    module_config_name: "",
    submodule_config: {},
    submodule_config_name: "",
    module_variables: {},
    module_variables_name: "",
    module_list_active_keys: [],
    selected_modules_list: [],
    selected_modules_dict: {},
    user_data: null,
    plant_info: {},
    notification_settings: {'showProject': false, 'showCoreId': false, 'showSubCodes': false, 'showFullMessages': false, 'itemsPerPage': 25}
  },
  mutations: {
    SET_HOST() {
      axios.defaults.baseURL = Utils.getBaseURL()
    },
    SET_USER_DATA(state, userData) {
      console.log("Mutuation SET_USER_DATA");
      console.log(userData.data.access_token);
      state.user_data = userData;
      localStorage.setItem('user_data', JSON.stringify(userData));
      axios.defaults.headers.common['Authorization'] = `Bearer ${
        userData.data.access_token
      }`
    },
    CLEAR_USER_DATA(state) {
      state.user_data = null;
      localStorage.removeItem('user_data');
      delete axios.defaults.headers.common['Authorization']
      console.log("------------------------------------- Logout------------------------------------------")
    },
    ADD_SELECTED_MODULE(state, value) {
      if (!state.selected_modules_list.includes(value)) {
        state.selected_modules_list.push(value);
      }
      state.selected_modules_dict[value] = {}
      console.log(state.selected_modules_dict);
    },
    REMOVE_SELECTED_MODULE(state, value) {
      const index = state.selected_modules_list.indexOf(value);
      if (index > -1) {
        state.selected_modules_list.splice(index, 1);
      }
      if (value in state.selected_modules_dict) {
        delete state.selected_modules_dict[value];
      }
      console.log(state.selected_modules_dict);

    },
    IS_SELECTED_MODULE(state, result) {
      //result.value = state.selected_modules_list.includes(result.name);
      result.value = result.name in state.selected_modules_dict
    },
    SELECTED_MODULE_SET_LEVEL(state, [name, level]) {
      if (name in state.selected_modules_dict) {
        state.selected_modules_dict[name]["level"] = level;
      }
      console.log(state.selected_modules_dict);
    },
    SELECTED_MODULE_GET_LEVEL(state, result) {
      if (result.name in state.selected_modules_dict) {
        result.value = state.selected_modules_dict[result.name]["level"];
      } else {
        result.value = "20";
      }
    }
  },
  actions: {
    set_host({ commit }) {
      commit('SET_HOST');
    },
    login ({ commit }, credentials) {
      return ModuleServices.login(credentials)
        .then(({ data }) => {
          commit('SET_USER_DATA', data)
          console.log(data);
        })
    },
    logout({ commit }) {
      console.log("++++++++++++++++++++++++ logout ++++++++++++++++++++++++++++++++++++++")
      commit('CLEAR_USER_DATA')
    }
  },
  modules: {
  },
  getters:
  {
    loggedIn (state) {
      console.log("Check loggedIn");
      return !!state.user_data;
    },
    loggedInService(state) {
      console.log("Check Service");
      if (state.user_data != null) {
        console.log(state.user_data)
        return state.user_data.data.level >= 2;
      } else {
        return false;
      }
    },
    loggedInAdmin(state) {
      return (!!state.user_data) && (state.user_data.data.level >= 3)
    },
    loggedInEsos(state) {
      return (!!state.user_data) && (state.user_data.data.level >= 4)
    },
    loggedInCalendar(state) {
      return (!!state.user_data) && (state.user_data.data.level == 1)
    }
  }
})
