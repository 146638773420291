<template>
    <div class="edit-param-int" >
      <span style="font-weight:bold">{{ param.name }}</span> (Int)
        {{ getDescription(param) }}
        <InputNumber v-model:value="param_temp.value"></InputNumber>
    </div>
</template>

<script>
import { InputNumber } from 'ant-design-vue';

export default {
  data() {
    return {
      param_temp: {},
    }
  },
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: { 
    InputNumber,
  },
  created() {
    this.param_temp = this.param;
  }, 
  methods:
  {
    getDescription(param) {
      if (param.description) {
        return " ( " + param.description + " )";
      } else {
        return ""
      }
    }
  }
}
</script>

<style scoped>
.edit-param-int {
  padding: 2px;
  width: 100%;
  height: 100%;
  margin-bottom: 2px;
}

</style>