
<template>
  <a-layout :style="{height:'100%'}" >
    <a-layout-sider :width=menuwidth height='100%' :style="{overflow: 'auto'}" v-on:mouseover=mouseOverMenu() v-on:mouseleave=mouseLeaveMenu()
      class="modulemenu">
      <div style="display:flex">
        <double-right-outlined  style="float:left; margin:0px"
          v-if="collapsed"
          class="trigger"
          @click="() => unfoldMenu()"
        />
        <double-left-outlined  style="float:left; margin:0px" 
          v-else
          class="trigger"
          @click="() => foldMenu()"
        />
      </div>
      <div scrollable-container style="width:580px"  >
<!--
         <a-tree :tree-data="treeData" show-icon default-expand-all>
            <template #smile>
              <p>lsdfjk</p>
            </template>
            <template #meh>
              <p>lsdfjk</p>
            </template>
            <template #testtext="{title}">
              <button>{{title.name}} - {{title.description}}</button>
            </template>
          </a-tree>

-->
        <a-collapse v-model:activeKey="activeKey" @change=onActiveKeyChange>
          <a-collapse-panel v-for="(group, index) in modules" :key="index" :header="index">
            <template #extra>
              <a-badge :count="getModuleCommErrorCount(group)" :title="getModuleCommErrorTitle(group)">
              </a-badge>
            </template>
            <VariablesModuleCard v-for="module in group" :key="module.name" :module="module" :group="index" @update-module-list="onUpdateModuleList"/>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </a-layout-sider>
    <a-layout-content :style="{overflow: 'auto'}">
      <div>
        <div class="box">
          <a-card v-for="(item, index) in moduleVariables" :key="index" class="subbox" :title=getReducedModuleName(item.name) width:100px>
            <template #extra>
              <a-button shape="circle" @click=onClose(item.name)>
                <template #icon><CloseOutlined/></template>
              </a-button>
            </template>
            <a-collapse v-model:activeKey="activeKey2">
              <a-collapse-panel v-for="(vlitem, vlindex) in item.variables" :key="vlindex" :header="vlitem.name">
                <template v-for="(vitem, vindex) in vlitem.variables" :key="vindex">
                  <table style="width:100%;max-width: 400px;">
                  <tr>
                    <td style="text-align:left;padding-right:5px;max-width:150px;overflow-wrap: anywhere">
                      {{vitem.name}}
                      <a-tooltip placement="topLeft" :title="get_notification_tooltip(vitem)" arrow-point-at-center>
                        <a-badge :count="get_notification_count(vitem)" />
                      </a-tooltip>
                    </td>
                    <td style="text-align:right">{{get_value(vitem.value, vitem.type)}}</td>
                    <td style="text-align:center; width:50px">{{vitem.unit}}
                      <a-switch v-if="vitem.infos" v-model:checked="bitmaskVisible[vitem.name]"/>
                    </td>
                    </tr>
                  <template v-if="bitmaskVisible[vitem.name]">
                    <tr v-for="(bitem, bindex) in vitem.infos" :key="bindex">
                    <td style="text-align:left;padding-right:20px;padding-left:20px;overflow-wrap: anywhere"  v-bind:class="{hasNotificationActive:has_notification_active(bitem), hasNotificationInactive:has_notification_inactive(bitem)}">
                      {{bitem.name}} 
                      <a-tooltip placement="topLeft" :title="get_notification_tooltip(bitem)" arrow-point-at-center>
                        <a-badge :count="get_notification_count(bitem)">
                        </a-badge>
                      </a-tooltip>
                    </td>
                    <td style="text-align:right"  v-bind:class="{hasNotificationActive:has_notification_active(bitem), hasNotificationInactive:has_notification_inactive(bitem)}">{{bitem.value}}</td>
                    <td style="text-align:center; width:50px"></td>
                  </tr>

                  </template>
                </table>
                </template>
              </a-collapse-panel>
            </a-collapse>
          </a-card>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
// @ is an alias to /src

import store from '../vuex/store.js'
import ModuleServices from '@/services/ModuleServices.js'
import VariablesModuleCard from '@/components/VariablesModuleCard.vue'
import { CloseOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons-vue';
import Utils from '../services/Utils.js';

export default {
  name: 'EsosVariables',
  data() {
    return {
      collapsed: false,
      menuwidth: "600px",
      activeKey: ['Driver'],
      modules: null,
      comm_status: null,
      dialogNewVisible: false,
      confirmLoading: false,
      module_classes: [],
      openModuleClassKeys: [],
      selectedModuleClassKeys: [],
      newModuleId: 0,
      status_text: "",
      rootSubmenuKeys: [],
      moduleVariables: {},
      timeoutLoop: null,
      bitmaskVisible: {},
      activeKey2: []
/*
  treeData: [
  {
    title: 'Aggregation',
    key: '0-0',
    children: [
      {
        title: {name:'Property-Name', description:'testtext'},
        key: '0-0-0',
        slots: {
          title: 'testtext'
        },
      },
      {
        title: 'asdfas',
        key: '0-0-1',
        slots: {
          title: 'testtext'
        },
      },
    ],
  },
]*/
    };
  },
  created() {
    this.onLoadModuleList();
  },
  mounted() {
      this.activeKey = store.module_list_active_keys;
      console.log("Start loop");
      this.timeoutLoop = setTimeout(this.onReadVariables, 1000);
  },
  unmounted() {
      console.log("Stop loop");
      clearTimeout(this.timeoutLoop);
      this.timeoutLoop = null;
  },
  components: {
    VariablesModuleCard,
    CloseOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined
  },
  methods: {
    onLoadModuleList() {
      ModuleServices.getModules()
        .then(response => {
          this.modules = response.data.modules
          const sort = (obj) => Object.keys(obj).sort((a, b) => 
              { 
                if (a == "Core") return 1; if (b == "Core") return -1; return Utils.compare(a, b);
              }).reduce((acc, c) => { acc[c] = obj[c]; return acc }, {})
          this.modules = sort(this.modules)
          for (const [key] of Object.entries(this.modules)) {
            this.modules[key] = this.modules[key].sort((a, b) => {
              return Utils.compare(Utils.getStringWithLeadingZeros(a.name, 4), Utils.getStringWithLeadingZeros(b.name, 4));
            });
          }
          for (const [key] of Object.entries(this.modules)) {
            this.rootSubmenuKeys.push(key)
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        })
      //this.activeKey = $store.module_list_active_keys;
    },
    parseCommStatus() {
      for (const [gkey] of Object.entries(this.modules)) {
        for (const [mkey] of Object.entries(this.modules[gkey])) {
          let module = this.modules[gkey][mkey];
          module.comm_status = this.comm_status[module.name]
          if (module.sub_module_list != null) {
            for (const [sgkey] of Object.entries(module.sub_module_list)) {
              for (const [smkey] of Object.entries(module.sub_module_list[sgkey])) {
                let smodule = module.sub_module_list[sgkey][smkey];
                smodule.comm_status = this.comm_status[smodule.name];
              }
            }
          }
        }
      }
    },
    onModulesNew() {
      console.log("Insert new Module")
      ModuleServices.getModuleClasses()
        .then(response => {
          this.module_classes = response.data.module_classes
        })
        .catch(error => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        })
      this.dialogNewVisible = true
    },
    getReducedModuleName(name) {
      var result = name.replace("EsosModule", "");
      if (result.length > 30) {
        var list = result.split("_")
        var id = list[list.length-1]
        list.pop()
        result = list.join("_")
        result = result.substring(0, 30) + "..._" + id
      }
      return result;
    },
    onDialogNewOk() {
      console.log("NEW Module: " + this.selectedModuleClassKeys[0] + "_" + this.newModuleId);
      ModuleServices.createNewModule(this.selectedModuleClassKeys[0], this.newModuleId)
      .then(response =>  {
        this.status_text = response.data.status.text
        if (response.data.status.value == 0) {
          this.$notification['success']({
            message: 'Create new module',
            description: this.status_text
          })
        } else {
          this.$notification['warning']({
            message: 'Create new module',
            description: this.status_text
          });
        }
        console.log(this.status);
        this.onLoadModuleList();
      })
      this.dialogNewVisible = false;
      // reload module list
      this.onLoadModuleList()
    },
    onDialogNewCancel() {
      console.log("NEW Cancel");
      this.dialogNewVisible = false;
    },
    onOpenChange(openKeys) {
      console.log("onOpenChange");
      console.log(openKeys);
      console.log(this.openModuleClassKeys);
      const latestOpenKey = openKeys.find(key => this.openModuleClassKeys.indexOf(key) === -1);
      console.log(latestOpenKey);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openModuleClassKeys = openKeys;
      } else {
        this.openModuleClassKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onUpdateModuleList() {
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
      this.onLoadModuleList();
    },
    onActiveKeyChange() {
      store.module_list_active_keys = this.activeKey;
    },
    onReadVariables() {
      ModuleServices.getModuleVariables()        
        .then(response => {
          this.moduleVariables = response.data.modules
          this.comm_status = response.data.comm_status
          this.parseCommStatus()
        })
        .catch(error => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        })
      this.timeoutLoop = setTimeout(this.onReadVariables, 1000);
    },
    onClose(name) {
      console.log("Close Card: " + name)
      store.commit("REMOVE_SELECTED_MODULE", name);
      this.onUpdateModuleList();
    },
    get_value(value, var_type) {
      if (var_type == "float") {
        try {
          // convert float value to locale string according to current browser locale
          // output 0-3 decimal places
          return value.toLocaleString(undefined, {useGrouping: false, minimumSignificantDigits: 5, maximumSignificantDigits: 7});
        } catch {
          return value
        }
      } else {
        return value;
      }
    },
    has_notification_active(item) {
      if (('notification' in item) && (item.notification)) {
        return item.notification.value;
      } else {
        return false;
      }
    },
    has_notification_inactive(item) {
      if (('notification' in item) && (item.notification)) {
        return !item.notification.value;
      } else {
        return false;
      }
    },
    get_notification_tooltip(item) {
      if (this.has_notification_active(item)) {
        try {
          var duration = Math.round(item.notification.duration)
          return "Notification is active since " + duration + "s";
        } catch {
          return "Notification is active";
        }
      } else if (this.has_notification_inactive(item)) {
        return "Notification is inactive";
      } else {
        return "";
      }
    },
    get_notification_count(item) {
      var count = 0;
      if (this.has_notification_active(item)) {
        count = 1;
      } else {
        count = 0;
      }
      // check notification in bitmask this.bitmaskVisible
      if ('infos' in item) {
        for (const bitem in item.infos) {
          count += this.get_notification_count(item.infos[bitem]);
        }
      }
      return count;
    },
    foldMenu() {
      this.collapsed = true;
      this.menuwidth = "20px";
    },
    unfoldMenu() {
      this.collapsed = false;
      this.menuwidth = "600px";
    },
    mouseOverMenu() {
      if (!this.collapsed) return;
      this.menuwidth = "600px";
    },
    mouseLeaveMenu() {
      if (!this.collapsed) return;
      this.menuwidth = "20px";
    },
    getModuleCommErrorCount(group) {
      let count = 0;
      for (const mkey in group) {
        let module = group[mkey];
        let hasError = false;
        for (const key in module.comm_status) {
          if (module.comm_status[key].value != 0) {
            hasError = true;
            //count++;
          }
        }
        if (hasError)
          count++;
      }
      return count;
    },
    getModuleCommErrorTitle(group) {
      let title = "Error in " + this.getModuleCommErrorCount(group) + " modules!";
      for (const mkey in group) {
        let module = group[mkey]
        for (const key in module.comm_status) {
          if (module.comm_status[key].value == 0) 
            continue;   // show only errrors
          title += "\n";
          title = title + this.getReducedModuleName(module.name) + ": "  + key + ": (" + module.comm_status[key].value + ") " + module.comm_status[key].text;
        }
      }
      return title;
    }



  },
}
</script>

<style>
.ant-collapse-header {
  padding: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.ant-card-head-title{
  font-size: 16px;
  font-weight: bold;
}

.box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.subbox {
  height: auto;
  width: 400px;
  border-style: solid;
}

/*
.hasNotificationActive {
  background-color: rgb(253, 215, 215);
}

.hasNotificationInactive {
  background-color: rgb(224, 255, 224);
}


*/
</style>
