<template>
  <div style="maxWidth:1400px;height:100%">
  <div style="font-size:24px;font-weight:bold">{{ moduleName }}</div>
  <div style="overflow:scroll; height:99%">
  <div class="config_container">---------</div>
  <a-button type="primary" @click=onSaveConfig>Save Config</a-button>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { createApp } from "vue"
import router from '../router/index.js'
import ModuleServices from '@/services/ModuleServices.js'
import EditConfigFloat from '../components/EditConfigFloat.vue';
import EditConfigInt from '../components/EditConfigInt.vue';
import EditConfigString from '../components/EditConfigString.vue';
import EditConfigPassword from '../components/EditConfigPassword.vue';
import EditConfigBool from '../components/EditConfigBool.vue';
import EditConfigTopic from '../components/EditConfigTopic.vue';
import EditConfigIpAddress from '../components/EditConfigIpAddress.vue';
import EditConfigSubmodule from '../components/EditConfigSubmodule.vue';
import EditConfigNotifications from '../components/EditConfigNotifications.vue';
import EditConfigEmergencyCalendar from '../components/EditConfigEmergencyCalendar.vue';

export default {
  name: 'ConfigurationModuleDetail',
  data() {
    return {
      moduleConfig: null,
      moduleName: ""
    };
  },
  mounted() {
    console.log("CMD 1");
    this.moduleConfig = this.$store.module_config;
    this.moduleName = this.$store.module_config_name;
    console.log("CMD 2");
    if (this.moduleConfig !== undefined) {
          document.querySelector('.config_container').innerHTML = '';
          // eslint-disable-next-line no-unused-vars
          for (const [gkey, gvalue] of Object.entries(this.moduleConfig)) {
            const p_wrapper = document.createElement("p");
            p_wrapper.innerText = gkey
            p_wrapper.className = "configHeader"
            document.querySelector('.config_container').appendChild(p_wrapper);
            const table_wrapper = document.createElement("table");
            table_wrapper.style.width = "100%"
            table_wrapper.style.padding = "20px"
            
            document.querySelector('.config_container').appendChild(table_wrapper);
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(gvalue)) {
              console.log(value);

              var comp = null;
              if (value.config_type.editor_type === "float")
                comp = createApp(EditConfigFloat, { "config": value });
              else if (value.config_type.editor_type === "int")
                comp = createApp(EditConfigInt, { "config": value });
              else if (value.config_type.editor_type === "pwd")
                comp = createApp(EditConfigPassword, { "config": value });
              else if (value.config_type.editor_type === "bool")
                comp = createApp(EditConfigBool, { "config": value });
              else if (value.config_type.editor_type === "topic") 
                comp = createApp(EditConfigTopic, { "config": value });
              else if (value.config_type.editor_type === "ip")
                comp = createApp(EditConfigIpAddress, { "config": value });
              else if (value.config_type.editor_type === "submodule")
                comp = createApp(EditConfigSubmodule, { "config": value });
              else if (value.config_type.editor_type === "notifications")
                comp = createApp(EditConfigNotifications, { "config": value });
              else if (value.config_type.editor_type === "emergencycalendar")
                comp = createApp(EditConfigEmergencyCalendar, { "config": value });
              else
                comp = createApp(EditConfigString, { "config": value });
              const wrapper = document.createElement("tr");
              comp.use(this.$router);
              comp.use(this.$store)
              comp.mount(wrapper);
              table_wrapper.appendChild(wrapper);
            }
          }        
    }
  },
  components: {
//    EditConfigFloat
  },
  methods: {
    openNotificationWithIcon(type, message, description, duration=5) {
      const key = this.module_name;
      this.$notification[type]({
          key,
          message: message,
          description: description,
          duration: duration
      });
    },

    onSaveConfig() {
      console.log("Save Config of module " + this.module_name);
      console.log(this.moduleConfig);
      ModuleServices.saveModuleConfig(this.moduleName, this.moduleConfig)
        .then(response => {
          console.log(response);
          if (response.data.status.value == 0) {
            this.openNotificationWithIcon("success", `The configuration of module ${this.moduleName} was saved successfully`);
          } else {
            this.openNotificationWithIcon("error", 'Error when saving the configuration.', `The configuration of module ${this.moduleName} could not be saved. Error (${response.data.status.value}): ${response.data.status.text}`, 0);
          }
        })
        .catch(error => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        })
      //router.back();
      router.push('/configuration_modules')
    } 
  }
}
</script>


<style scoped>
table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}


table,
tr,
th {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 10px;
  text-align: left;
}
</style>

<style>
.configHeader {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
}

</style>