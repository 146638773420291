ä<template>

<svg class="svg_with_border" 
  viewBox="0 0 141.5 151.5"
  xmlns="http://www.w3.org/2000/svg"
  :width="width" 
  :height="height" 
>

<path id="white_back" class="st0" d="M134,150.8H8.1c-3.9,0-7-3.1-7-7V7.9c0-3.9,3.1-7,7-7H134c3.9,0,7,3.1,7,7v135.9
	C141,147.7,137.8,150.8,134,150.8z"/>
<text transform="matrix(1 0 0 1 45.4238 141.7713)" class="st1 st2 st3">{{t.TradedPower}}</text>
<text transform="matrix(1 0 0 1 44.708 127.6639)" class="st2 st4">{{round(p.vTradedPowerVneId, 0)}}<tspan class="st1 st2 st3"> kW</tspan></text>
<text transform="matrix(1 0 0 1 45.8476 105.5192)" class="st2 st6">{{t.VneId}}</text>
<path id="p_vneid_false" class="st7" d="M26.6,106.6c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.2-0.4-0.3-0.6-0.5c-0.6-0.6-1.2-1.1-1.8-1.7
	c-0.7-0.6-1.3-1.2-2-1.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.6,0.7-1.3,1.4-1.9,2.1c-0.6,0.7-1.3,1.4-1.9,2.1c-0.1-0.1-0.2-0.1-0.3-0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.6,0.4-0.8c0.2-0.3,0.4-0.7,0.6-1
	c0.3-0.5,0.6-0.9,0.9-1.4c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.4-0.9-0.8-1.4-1.2
	c-0.4-0.3-0.7-0.6-1.1-0.9c-0.3-0.3-0.6-0.5-0.9-0.8c-0.2-0.1-0.4-0.3-0.5-0.4c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3
	c0-0.2,0.1-0.3,0.3-0.3c0.3,0,0.5,0.1,0.7,0.1c0.4,0.1,0.8,0.3,1.3,0.5c0.3,0.2,0.6,0.3,0.9,0.5c0.3,0.2,0.7,0.4,1,0.6
	c0.4,0.3,0.8,0.5,1.1,0.8c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0,0.1,0c0.6-0.6,1.3-1.1,1.9-1.7c0.4-0.4,0.9-0.7,1.4-1.1
	c0.4-0.3,0.9-0.6,1.3-1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.3-0.1,0.5
	c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-0.7,0.8-1,1.2c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.2-0.4,0.5-0.7,0.7
	c-0.3,0.3-0.6,0.7-0.9,1c0,0,0,0.1,0,0.1c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.6,0.8,1c0.4,0.5,0.8,1,1.1,1.6
	c0.3,0.5,0.6,1,0.8,1.5c0.1,0.2,0.2,0.5,0.3,0.7C26.7,106.4,26.7,106.5,26.6,106.6z"/>
<path id="p_vneid_true" class="st8" d="M23.6,99.9c-1.3,2.1-2.3,4.2-3.3,6.5l-1.9,0c-0.1-0.4-0.3-0.7-0.4-1.1
	c-0.6-1.3-1.2-2.6-2-3.8c-0.2-0.2-0.3-0.4-0.5-0.7c-0.2-0.2-0.2-0.4,0-0.6c0.4-0.3,0.9-0.6,1.3-0.9c0.3-0.1,0.5,0,0.6,0.2
	c0.4,0.6,0.8,1.2,1.2,1.8c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.4,0.5,0.4,0.8,0.1c0.7-0.9,1.4-1.8,2.1-2.7c1.7-2,3.6-3.7,5.9-5.1
	c0.1-0.1,0.2-0.1,0.3-0.1C26.4,96,24.9,97.8,23.6,99.9z"/>
<text transform="matrix(1 0 0 1 45.4238 79.0028)" class="st1 st2 st3">{{t.TradedPower}}</text>
<text transform="matrix(1 0 0 1 44.708 64.8953)" class="st2 st4">{{round(p.vTradedPowerFcr, 0)}}<tspan class="st1 st2 st3"> kW</tspan></text>
<text transform="matrix(1 0 0 1 45.8476 42.869)" class="st2 st6">{{t.Fcr}}</text>
<path id="p_fcr_false" class="st7" d="M26.6,43.9c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.2-0.4-0.3-0.6-0.5c-0.6-0.6-1.2-1.1-1.8-1.7
	c-0.7-0.6-1.3-1.2-2-1.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.6,0.7-1.3,1.4-1.9,2.1c-0.6,0.7-1.3,1.4-1.9,2.1c-0.1-0.1-0.2-0.1-0.3-0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.6,0.4-0.8c0.2-0.3,0.4-0.7,0.6-1
	c0.3-0.5,0.6-0.9,0.9-1.4c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.4-0.9-0.8-1.4-1.2
	c-0.4-0.3-0.7-0.6-1.1-0.9c-0.3-0.3-0.6-0.5-0.9-0.8c-0.2-0.1-0.4-0.3-0.5-0.4c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3
	c0-0.2,0.1-0.3,0.3-0.3c0.3,0,0.5,0.1,0.7,0.1c0.4,0.1,0.8,0.3,1.3,0.5c0.3,0.2,0.6,0.3,0.9,0.5c0.3,0.2,0.7,0.4,1,0.6
	c0.4,0.3,0.8,0.5,1.1,0.8c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0,0.1,0c0.6-0.6,1.3-1.1,1.9-1.7c0.4-0.4,0.9-0.7,1.4-1.1
	c0.4-0.3,0.9-0.6,1.3-1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.3-0.1,0.5
	c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-0.7,0.8-1,1.2c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.2-0.4,0.5-0.7,0.7
	c-0.3,0.3-0.6,0.7-0.9,1c0,0,0,0.1,0,0.1c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.6,0.8,1c0.4,0.5,0.8,1,1.1,1.6
	c0.3,0.5,0.6,1,0.8,1.5c0.1,0.2,0.2,0.5,0.3,0.7C26.7,43.7,26.7,43.8,26.6,43.9z"/>
<path id="p_fcr_true" class="st8" d="M23.6,37.2c-1.3,2.1-2.3,4.2-3.3,6.5l-1.9,0c-0.1-0.4-0.3-0.7-0.4-1.1c-0.6-1.3-1.2-2.6-2-3.8
	c-0.2-0.2-0.3-0.4-0.5-0.7c-0.2-0.2-0.2-0.4,0-0.6c0.4-0.3,0.9-0.6,1.3-0.9c0.3-0.1,0.5,0,0.6,0.2c0.4,0.6,0.8,1.2,1.2,1.8
	c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.4,0.5,0.4,0.8,0.1c0.7-0.9,1.4-1.8,2.1-2.7c1.7-2,3.6-3.7,5.9-5.1c0.1-0.1,0.2-0.1,0.3-0.1
	C26.4,33.3,24.9,35.1,23.6,37.2z"/>
<path id="blue_back" class="st9" d="M141,20H1c0,0,0-4,0-8.8V9.6c0-4.9,2.2-8.8,8.1-8.8h123.8c5.9,0,8.1,4,8.1,8.8v1.5
	C141,16.1,141,20,141,20z"/>
<text transform="matrix(1 0 0 1 11.3232 14.7225)" class="st10 st2 st11">{{t.Usecases}}</text>

</svg>
</template>

<script>
export default {
  name: 'PlantModi',
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    p: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  methods: {
    get_p_status_text() {
      if (this.p.vPlantStatus) {
        return "active";
      } else {
        return "inactive";
      }
    },
    get_p_status_color() {
      if (this.p.vPlantStatus) {
        return "RGB(0, 210, 0)";
      } else {
        return "red";
      }
    },
    get_p_usecases_vneid_visibility(active) {
      if (this.p.vUsecaseVndIdStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_fcr_visibility(active) {
      if (this.p.vUsecaseFcrStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    round(value, count) {
      try {
        return value.toFixed(count);
      } catch {
        return "---"
      }
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}

#p_status {
  fill: v-bind('get_p_status_color()') !important;
}

#p_vneid_false {
  visibility: v-bind('get_p_usecases_vneid_visibility(false)') !important;
}
#p_vneid_true {
  visibility: v-bind('get_p_usecases_vneid_visibility(true)') !important;
}
#p_fcr_false {
  visibility: v-bind('get_p_usecases_fcr_visibility(false)') !important;
}
#p_fcr_true {
  visibility: v-bind('get_p_usecases_fcr_visibility(true)') !important;
}

	.st0{fill:#FFFFFF;stroke:#969696;stroke-width:0.5;stroke-miterlimit:10;}
	.st1{fill:#939393;}
	.st2{font-family:'Verdana';}
	.st3{font-size:9px;}
	.st4{font-size:16px;}
	.st5{fill:#E0E0E0;}
	.st6{font-size:12px;}
	.st7{fill:#BF3C3C;}
	.st8{fill:#188C47;}
	.st9{fill:#254965;}
	.st10{fill:#FFFFFF;}
	.st11{font-size:11px;}

</style>