import { createApp } from 'vue'
import App from './App.vue'
import { Layout, Menu, Button, Input, InputNumber, Form, Modal, Dropdown, Popconfirm, Popover, Divider, Switch, FormItem, Select, RadioButton, Checkbox, Collapse, Card, Table, Tag, Col, Row, Tooltip, Badge, Tree, Calendar, Space, Avatar } from 'ant-design-vue'
import { message, notification } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import router from './router'
import store from './vuex/store.js'

const app = createApp(App);
app.use(Layout);
app.use(Menu);
app.use(Button);
app.use(Input);
app.use(InputNumber);
app.use(Form);
app.use(FormItem);
app.use(Modal);
app.use(Dropdown);
app.use(Popconfirm);
app.use(Popover)
app.use(Divider);
app.use(Switch);
app.use(Select);
app.use(RadioButton);
app.use(Checkbox);
app.use(Collapse);
app.use(Card);
app.use(Table);
app.use(Tag);
app.use(Row);
app.use(Col);
app.use(Tooltip);
app.use(Badge);
app.use(Tree);
app.use(Calendar);
app.use(Space);
app.use(Avatar);
app.use(store);
app.use(router);

app.config.globalProperties.$message = message;
app.config.globalProperties.$notification = notification;
app.mount('#app');
