<template>
  <a-layout :style="{ height: '100%'}">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible :style="{ overflow: 'auto'}">
        <div style="background-color:#172736; height:70px !important">
          <div style="height:10px"/>
          <img src="./assets/Logo.png" width="90" height="50">
          <hr color="grey" noshade="noshade" margin="0px" />
        </div>
        <a-menu
          
          mode="inline" 
          v-model:selected-keys="selectedKeys"
          v-model:openKeys="openKeys">
          <a-menu-item key="Home">
            <router-link to="/">
              <HomeOutlined />
              <span>Home</span>
            </router-link>
          </a-menu-item>

          <a-sub-menu v-if="loggedInService" 
              :disabled=false
              key="info">
            <template #icon>
              <InfoCircleOutlined/>
            </template>
            <template #title>Information</template>
            <a-menu-item key="VariablesModules">
              <router-link to="/variables_modules">
                <SettingOutlined />
                <span>Modules</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="NotificationList">
              <router-link to="/notification_list">
                <NotificationOutlined />
                <span>Notifications</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="LoggingList">
              <router-link to="/logging_list">
                <DatabaseOutlined />
                <span>Logging</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>

          <a-sub-menu v-if="loggedInAdmin" 
              :disabled=false
              key="config">
            <template #icon>
              <SettingOutlined/>
            </template>
            <template #title>Configuration</template>
            <a-menu-item key="ConfigurationModules">
              <router-link to="/configuration_modules">
                <SettingOutlined />
                <span>Modules</span>
              </router-link>
            </a-menu-item>

          </a-sub-menu>
                    

          <a-menu-item v-if="!loggedIn" key="Login">
            <router-link to="/login">
              <LoginOutlined />
              <span>Login</span>
            </router-link>
          </a-menu-item>



        </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header :style="{ background: 'white', padding: 0 }" style="font-weight:bold; font-size:24px">
        <menu-unfold-outlined style="float:left; margin:20px"
          v-if="collapsed"
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
        <div v-if="loggedInService" style="float:left; padding-right:30px; padding-left:30px">
          <a-tooltip v-for="(state, index) in $store.state.plant_info.vBatsysUiStatus" :key="index">
            <template #title>{{getBatsysTooltip(index, state)}}</template>
            <a-avatar :size="20" :class="getBatsysStateClass(state)" style="font-size: 14px;margin-left:1px">
              {{ index }}
            </a-avatar>
          </a-tooltip>
        </div>
        {{plant_name}}
        <a-button v-if="!loggedIn" class="loginButton" style="float:right; margin:20px">
          <router-link  to="/login">
            <LoginOutlined />
            Login
          </router-link>
        </a-button>
        <a-button v-else class="logoutButton" @click="logout" style="float:right; margin:20px">
          <LogoutOutlined />
          Logout ({{$store.state.user_data.data.username}})
        </a-button>
        <a-tooltip v-if="versionOutdated()" placement="topLeft">
          <template #title>
            <span>{{version_outdated_message}}</span>
          </template>        
          <warning-outlined style="float:right; vertical-align:center; padding-left: 10px; padding-top:16px; color:red">
          </warning-outlined>
        </a-tooltip>
        <div style="vertical-align:center; float:right; font-weight:normal; font-size:12px; padding-top:16px">
          <p style="with:100%; height:20px; font-weight:normal; font-size:12px; line-height:20px; margin:0px; padding:0px">ESOS v{{core_version}}</p>
          <p style="with:100%; height:20px; float:both; font-weight:normal; font-size:12px; line-height:20px; margin:0px;  padding:0px">WEB v{{version}}</p>
        </div>
        <a-popover title="Maintenance Mode active">
          <template #content>
            <p>Start Time: {{ getMaintenanceModeStartTime() }}</p>
            <p>Reset Time: {{ getMaintenanceModeResetTime() }}</p>
          </template>
          <div v-if="maintenanceModeActive()" style="float:right; padding-right:30px; padding-left:30px; color:#cd201f">
            Maintenance Mode active!
          </div>
        </a-popover>

      </a-layout-header>
      <a-layout-content
        :style="{ margin: '64px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
      >
      <router-view/>

      </a-layout-content>
    </a-layout>
  </a-layout>

  
</template>

<script>
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  NotificationOutlined,
  DatabaseOutlined,
  WarningOutlined

} from '@ant-design/icons-vue';
import { ref } from "vue";
import { authComputed } from './vuex/helpers.js'
import ModuleServices from "@/services/ModuleServices.js";
import Version from "@/services/Version.js";
//import store from '../vuex/store.js'
//import HelloWorld from './components/HelloWorld.vue'
export default {
  setup() {
    const collapsed = ref(false);
    return { collapsed };
  },
  created() {
    // add information about host to axios
    this.$store
        .dispatch('set_host')
    // default login
    const userString = localStorage.getItem('user_data') // grab user data from local storage
    if (userString) { // check to see if there is indeed a user
      const userData = JSON.parse(userString) // parse user data into JSON
      this.$store.commit('SET_USER_DATA', userData) // restore user data with Vuex
    }
    document.title = "ECO STOR EMS - Eisenach"
  },
  mounted() {
    // query project infos
    this.version = Version.getVersion();
    this.min_core_version = Version.getMinCoreVersion();
    ModuleServices.getInfo()
      .then((response) => {
        var plant_info = response.data.info.project_name;
        console.log(plant_info);
        console.log(response)
        this.plant_name = "ESOS - " + response.data.info.project_name; 
        this.core_version = response.data.info.version;
        this.min_webapp_version = response.data.info.min_webapp_version;
        document.title = this.plant_name;
      });

    // add current route in selectedKeys
    setTimeout(() => {
      // delay is necessary to read out current route correct
      this.selectedKeys.push(this.$route.name);
      console.log("-----------------");
      console.log(this.$route.name);      
    }, 10);
  },
  data: function() {
    return {
      selectedKeys: [],
      openKeys: ['info', 'config', 'calendar'],
      hiddenClass: 'hidden',
      logo: './assets/Logo.png',
      plant_name: "ESOS",
      core_version: "0.0.0",
      min_webapp_version: "0.0.0.0",
      version_outdated_message: "",
      version: "0.0.0.0",
      min_core_version: "0.0.0.0",
      maintenanceDialogVisible: false
    };
  },
  components: { 
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
    InfoCircleOutlined,
    HomeOutlined,
    LoginOutlined,
    LogoutOutlined,
    NotificationOutlined,
    DatabaseOutlined,
    WarningOutlined
  },
  methods: {
    logout() {
      this.$router.push({ name: 'Home' });
      this.$store.dispatch('logout');
    },
    compareVersion(v1, v2) {
      if (typeof v1 !== 'string') return false;
      if (typeof v2 !== 'string') return false;
      v1 = v1.split('.');
      v2 = v2.split('.');
      const k = Math.min(v1.length, v2.length);
      for (let i = 0; i < k; ++ i) {
          v1[i] = parseInt(v1[i], 10);
          v2[i] = parseInt(v2[i], 10);
          if (v1[i] > v2[i]) return 1;
          if (v1[i] < v2[i]) return -1;        
      }
      return v1.length == v2.length ? 0: (v1.length < v2.length ? -1 : 1);
    },
    versionOutdated() {
      if (this.compareVersion(this.version, this.min_webapp_version) < 0) {
        this.version_outdated_message = "Webapp version is outdated. Core needs at least version " + this.min_webapp_version + " of WebApp";
        return true;
      }

      if (this.compareVersion(this.core_version, this.min_core_version) < 0) {
        this.version_outdated_message = "Core version is outdated. Webapp needs at least version " + this.min_core_version + " of core!";
        return true;
      }
      this.webapp_version_outdated_message = "";
      return false;
    },
    maintenanceModeActive() {
      if (this.$store.state.plant_info.vMaintenanceModeActive) {
        return true;
      } else {
        return false;
      }
    },
    getMaintenanceModeStartTime() {
      return new Date(this.$store.state.plant_info.vMaintenanceModeStartTimeUTC * 1000);
    },
    getMaintenanceModeResetTime() {
      return new Date(this.$store.state.plant_info.vMaintenanceModeResetTimeUTC * 1000);
    },
    getBatsysStateClass(status) {
      if (status.locked) {
        return "batsys-state-locked";
      }
      if (status.manual) {
        return "batsys-state-manual";
      }
      switch(status.state) {
        case 1:
          return "batsys-state-1";
        case 2:
          return "batsys-state-2";
        case 3: 
          return "batsys-state-3";
        case 4:
          return "batsys-state-on";
        case 5:
        case 6:
        case 7:
          return "batsys-state-7"
        case 8:
        case 9:
          return "batsys-state-error";
      } 
    },
    getBatsysTooltip(id, state) {
      let s = "Batsys " + id + ": " + state.state;
      if (state.manual) {
        s += " (manual)";
      }
      if (state.locked) {
        s += " (locked)";
      }
      return s;
    }
  },
  computed: {
    ...authComputed
  }
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height:100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.trigger {
  float: left;
  margin-top: 25px;
  margin-left: 10px;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.hidden {
  visibility: hidden;
  height: 0px;
  margin: 0px;
}

.ant-layout-sider-children {
  background-color: white;
}

.ant-layout-content {
  margin: 10px !important;
}

.batsys-state-1 {
  background-color: blue !important;
}
.batsys-state-2 {
  background-color: cyan !important;
}
.batsys-state-3 {
  background-color: darkcyan !important;
}
.batsys-state-on {
  background-color: green !important;
}
.batsys-state-7 {
  background-color: purple !important;
}
.batsys-state-error {
  background-color: red !important;
}

.batsys-state-manual {
  background-color: grey !important;
}

.batsys-state-locked {
  background-color: orange !important;
}


/*
.ant-menu-submenu-selected {
  color: white !important;
}

.ant-menu-submenu-title {
  color: white;
}

.ant-menu-item a {
  color: white !important;
}

.ant-menu-submenu-active a {
  color: grey !important;
}

.ant-menu-item-selected a {
  color: white !important;
}

.ant-menu {
  background-color:#172736 !important;
}

.ant-layout-sider-childrenPa {
  background-color:#172736 !important;
}

.ant-menu-icon {
  background-color:#172736 !important;
  height: 80px;
  padding: 10px;
}

.ant-menu-separator {
  background-color:#172736 !important;
  color: red;
  height: 1px;
}

.ant-menu-item-selected {
  background-color:#45525E !important;
}

.ant-menu-submenu-arrow {
  color: white !important;
}

.ant-collapse-header {
  background-color: #45525E !important;
  background: #45525E !important;
  color: white !important;
}

.ant-collapse-item {
  background-color: #45525E !important;
  background: #45525E !important;
}

.ant-card-head-title {
  background-color:#45525E !important;
  color: white;
  padding: 10px !important;
}
*/
</style>
