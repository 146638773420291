<template>
      <td style="width:30%">
          <span style="font-weight:bold">{{ config.name }}</span> (String) {{getDescription(config)}}
      </td>
      <td>
        <Button class="editable-add-btn" @click="addReceiver" style="margin-bottom: 8px" type="primary">Add</Button>
        <Table :dataSource='config_temp.value.receivers' :columns="columns" rowKey="email">
          <template v-for="col in ['email']" #[col]="{ text, record }" :key="col">
            <div>
              <Input
                v-if="editableData[record.email]"
                v-model:value="editableData[record.email][col]"
                style="margin: -5px 0; width:100%"
              />
              <template v-else>
                {{ text}}
              </template>
            </div>
          </template>
          <template v-for="col in ['sys', 'drv', 'use', 'fct', 'cor', 'com', 'o&m']" #[col]="{ text, record }" :key="col">
            <div>
              <Select 
                v-if="editableData[record.email]"
                v-model:value="editableData[record.email][col]"
                style="margin: -5px 0; width:80px"
                :options="levelOptions"
              >
              </Select>
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template v-for="col in ['subcodes']" #[col]="{ text, record }" :key="col">
            <div>
              <Checkbox
                v-if="editableData[record.email]"
                v-model:checked="editableData[record.email][col]"
                style="margin: -5px 0; width:120px"
              />
              <template v-else-if ="text">
                X
              </template>
              <template v-else>
                -
              </template>
            </div>
          </template>
          <template v-for="col in ['active']" #[col]="{ record }" :key="col">
            <div>
              <Checkbox
                v-model:checked="record[col]"
                style="margin: -5px 0; widht:100%"
              />
            </div>
          </template>
          <template #operation="{ record }">
            <div class="editable-row-operations">
              <span v-if="editableData[record.email]">
                <a @click="save(record.email)">Save</a>
                <Popconfirm title="Sure to cancel?" @confirm="cancel(record.email)">
                  <a>Cancel</a>
                </Popconfirm>
              </span>
              <span v-else>
                <a @click="edit(record.email)">Edit</a>
                <Popconfirm
                  v-if="config_temp.value.receivers.length"
                  title="Sure to delete?"
                  @confirm="onDelete(record)"
                >
                  <a>Delete</a>
                </Popconfirm>
              </span>
            </div>
          </template>


        </Table>
      </td>
</template>

<script>
import { Table } from 'ant-design-vue';
import { Input } from 'ant-design-vue';
import { Select } from 'ant-design-vue';
import { Button } from 'ant-design-vue';
import { Checkbox } from 'ant-design-vue';
import { Popconfirm } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';

export default {
  data() {
    return {
      config_temp: {},
      editableData: {},
      columns: [
        {
          title: "Active",
          dataIndex: "active",
          key: "active",
          slots: {
            customRender: 'active',
          },
          width: 80
        },
        {
          title: "E-Mail (separated by , )",
          dataIndex: "email",
          key: "email",
          slots: {
            customRender: 'email',
          },
        },
        {
          title: "O&M",
          dataIndex: "o&m",
          key: "o&m",
          slots: {
            customRender: 'o&m',
          },
          width: 120
        },
        {
          title: "SYS",
          dataIndex: "sys",
          key: "sys",
          slots: {
            customRender: 'sys',
          },
          width: 120
        },
        {
          title: "DRV",
          dataIndex: "drv",
          key: "drv",
          slots: {
            customRender: 'drv',
          },
          width: 120
        },
        {
          title: "USE",
          dataIndex: "use",
          key: "use",
          slots: {
            customRender: 'use',
          },
          width: 120
        },
        {
          title: "FCT",
          dataIndex: "fct",
          key: "fct",
          slots: {
            customRender: 'fct',
          },
          width: 120
        },
        {
          title: "COR",
          dataIndex: "cor",
          key: "cor",
          slots: {
            customRender: 'cor',
          },
          width: 120
        },
        {
          title: "COM",
          dataIndex: "com",
          key: "com",
          slots: {
            customRender: 'com',
          },
          width: 120
        },
        {
          title: "SubCodes",
          dataIndex: "subcodes",
          key: "subcodes",
          slots: {
            customRender: 'subcodes',
          },
          width: 120
        },
        {
          title: "Operation",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
          width: 140
        },      
      ],
      levelOptions: [
        {
          value: '',
          label: '---',
        },
        {
          value: 'I',
          label: 'Info',
        },
        {
          value: 'W',
          label: 'Warn',
        },
        {
          value: 'E',
          label: 'Error',
        },
        {
          value: 'SL1',
          label: 'SL1',
        },
        {
          value: 'SL2',
          label: 'SL2',
        },
        {
          value: 'SL3',
          label: 'SL3',
        }
      ]
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    } 
  },
  components: { 
    Table,
    Input,
    Select,
    Button,
    Checkbox,
    Popconfirm
  },
  created() {
    this.config_temp = this.config;
  },
  methods: {
    edit(email) {
      console.log("Edit " + email);
      this.editableData[email] = cloneDeep(this.config_temp.value.receivers.filter(item => email === item.email)[0]);
      console.log(this.editableData[email])
    },
    save(email) {
      console.log("Save " + email);
      Object.assign(this.config_temp.value.receivers.filter(item => email === item.email)[0], this.editableData[email]);
      delete this.editableData[email];
    },
    cancel(email) {
      console.log("Cancel " + email);
      delete this.editableData[email];
    },
    addReceiver() {
      console.log("Add Receiver")
      this.config_temp.value.receivers.push({"email":"---"})
    },
    onDelete(record) {
      console.log("Delete " + record)
      this.config_temp.value.receivers = this.config_temp.value.receivers.filter(function(item) {
        return item !== record
      })

    },
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    }
  }
}
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px; 
  text-align: left;
}
</style>