ä<template>

<svg class="svg_with_border" 
  viewBox="0 0 141.5 151.5"
  xmlns="http://www.w3.org/2000/svg"
  :width="width" 
  :height="height" 
>
<path id="white_back" class="st0" d="M134,150.8H8.1c-3.9,0-7-3.1-7-7V7.9c0-3.9,3.1-7,7-7H134c3.9,0,7,3.1,7,7v135.9
	C141,147.7,137.8,150.8,134,150.8z"/>
<text transform="matrix(1 0 0 1 45.4238 101.0028)" class="st1 st2 st3">{{t.TotalCapacity}}</text>
<text transform="matrix(1 0 0 1 44.708 86.8953)" class="st5"><tspan x="0" y="0" class="st2 st4">{{round(p.vCapacity, 0)}}</tspan><tspan class="st1 st2 st3"> kWh</tspan></text>
<text transform="matrix(1 0 0 1 46.5635 59.9764)" class="st1 st2 st3">{{t.StateOfCharge}}</text>
<text transform="matrix(1 0 0 1 45.8476 45.869)" class="st5"><tspan x="0" y="0" class="st2 st4">{{round(p.vSOC, 1)}}</tspan><tspan class="st1 st2 st3"> %</tspan></text>
<g id="icon_batt">
	<path class="st6" d="M30.9,42.6c0-4.6,0,1.9,0-2.7c0-3.2-1.7-4.9-4.9-4.9c-3.6,0-5.5,0-9.2,0c-2.7,0-4.5,1.8-4.5,4.5
		c0,9.3,0,7.5,0,16.8c0,3.1,1.6,4.8,4.7,4.8c3.7,0,5.6,0,9.3,0c2.8,0,4.5-1.8,4.5-4.6C30.9,51.9,30.9,47.3,30.9,42.6L30.9,42.6z
		M29.2,56.6c0,1.9-1,2.9-2.9,2.9c-3.7,0-5.7,0-9.4,0c-1.9,0-2.8-1-2.8-2.9c0-9.3,0-7.6,0-16.9c0-1.9,1-2.9,2.9-2.9
		c3.6,0,5.6,0,9.2,0c2.1,0,3,0.9,3,3c0,4.6,0-1.8,0,2.9l0,0C29.2,47.3,29.2,51.9,29.2,56.6z"/>
	<path class="st6" d="M22.3,30.8h-1h-0.1c-1.4,0-2.6,1-2.9,2.7c2,0,4.8,0,6.8,0C24.8,32,23.6,30.9,22.3,30.8z"/>
</g>
<rect id="icon_batt_b1" x="16.4" y="54.3" class="st6" width="10.5" height="2.5"/>
<rect id="icon_batt_b2" x="16.4" y="49.3" class="st6" width="10.5" height="2.5"/>
<rect id="icon_batt_b3" x="16.4" y="44.4" class="st6" width="10.5" height="2.5"/>
<rect id="icon_batt_b4" x="16.4" y="39.5" class="st6" width="10.5" height="2.5"/>
<path id="blue_back" class="st6" d="M141,20H1c0,0,0-4,0-8.8V9.6c0-4.9,2.2-8.8,8.1-8.8h123.8c5.9,0,8.1,4,8.1,8.8v1.5
	C141,16.1,141,20,141,20z"/>
<text transform="matrix(1 0 0 1 11.3232 14.7225)" class="st7 st2 st8">{{t.Battery}}</text>

</svg>
</template>

<script>
export default {
  name: 'PlantBattery',
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    p: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  methods: {
    get_p_status_text() {
      if (this.p.vPlantStatus) {
        return "active";
      } else {
        return "inactive";
      }
    },
    get_p_status_color() {
      if (this.p.vPlantStatus) {
        return "RGB(0, 210, 0)";
      } else {
        return "red";
      }
    },
    get_p_usecases_vneid_visibility(active) {
      if (this.p.vUsecaseVndIdStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_fcr_visibility(active) {
      if (this.p.vUsecaseFcrStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_soc_greater(value) {
      if (this.p.vSOC >= value) {
        return "";
      } else {
        return "hidden";
      }
    },
    round(value, count) {
      try {
        return value.toFixed(count);
      } catch {
        return "---"
      }
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}

#p_status {
  fill: v-bind('get_p_status_color()') !important;
}

#p_vneid_false {
  visibility: v-bind('get_p_usecases_vneid_visibility(false)') !important;
}
#p_vneid_true {
  visibility: v-bind('get_p_usecases_vneid_visibility(true)') !important;
}
#p_fcr_false {
  visibility: v-bind('get_p_usecases_fcr_visibility(false)') !important;
}
#p_fcr_true {
  visibility: v-bind('get_p_usecases_fcr_visibility(true)') !important;
}
#icon_batt_b1 {
  visibility: v-bind('get_soc_greater(20)') !important;
}
#icon_batt_b2 {
  visibility: v-bind('get_soc_greater(40)') !important;
}
#icon_batt_b3 {
  visibility: v-bind('get_soc_greater(60)') !important;
}
#icon_batt_b4 {
  visibility: v-bind('get_soc_greater(80)') !important;
}

	.st0{fill:#FFFFFF;stroke:#969696;stroke-width:0.5;stroke-miterlimit:10;}
	.st1{fill:#939393;}
	.st2{font-family:'Verdana';}
	.st3{font-size:9px;}
	.st4{font-size:16px;}
	.st5{enable-background:new    ;}
	.st6{fill:#254965;}
	.st7{fill:#FFFFFF;}
	.st8{font-size:11px;}

</style>