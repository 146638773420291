<template>
    <div class="edit-param-float">
        <span style="font-weight:bold">{{ param.name }}</span> (Float)
        {{ getDescription(param) }}
        <Input v-model:value="unit_value" @change="onInputChanged" style="width:150px;text-align:right" ></Input>
        <span style="font-weight:bold;margin-left:10px">{{ unit_prefix }}{{param_temp.unit}}</span>  <span style="font-size:10px">({{param_temp.value}})</span>
    </div>
</template>

<script>

import { Input } from 'ant-design-vue';
import Utils from '../services/Utils';

export default {
  data() {
    return {
      param_temp: {},
      unit_factor: 1,
      unit_prefix: "",
      unit_value: ""
    }
  },
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: { 
    Input
  },
  mounted() {
    this.param_temp = this.param;
    this.unit_prefix = "";
    this.unit_factor = 1;
    var result = Utils.getFormattedFloatString(this.param.value, this.param_temp.unit)
    this.unit_value = result.value;
    this.unit_prefix = result.unit_prefix;
    this.unit_factor = result.unit_factor;
  },
  methods:
  {
    getDescription(param) {
      if (param.description) {
        return " ( " + param.description + " )";
      } else {
        return ""
      }
    },
    onInputChanged: function(e) {
      var result = Utils.setFormattedFloatString(e.target.value, this.param_temp.unit, this.unit_prefix, this.unit_factor);
      console.log("->", result);
      this.unit_prefix = result.unit_prefix;
      this.unit_factor = result.unit_factor;
      this.unit_value = result.unit_value;
      this.param_temp.value = result.value;
      
    },

  }
}
</script>

<style scoped>
.edit-param-float {
  padding: 2px;
  width: 100%;
  height: 100%;
  margin-bottom: 2px;
}

</style>