<template>
    <ConfigProvider :locale="deDE">
    <div style="height:100%; width:1200px ">
        <div class="ecalendar">
            <div>
                <div style="float:left; margin-left:20px">
                <h2>Notifcation Calendar</h2>
                </div>
                <div style="float:right; margin-right:30px">
                    <a-space>
                        <a-button class="editable-add-btn" @click="onDownload" style="margin-bottom: 8px" type="primary" :hidden="loggedInCalendar">Download CSV</a-button>
                        <a-button class="editable-add-btn" @click="onAddWorkingDays" style="margin-bottom: 8px" type="primary" :hidden="loggedInCalendar">Add working days</a-button>
                    </a-space>
                </div>
                </div>
                <div :style="{
                    display: 'inline-block',
                    marginLeft: '20px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    marginRight: '30px',
                }">
                <Calendar v-model:value="calendarValue" @select="onSelectDate" @panelChange="onPanelChange" locale="de">
                    <template #dateCellRender="{ current: value }">
                        <div v-html="getListData(value)"/>
                    </template>
                </Calendar>
            </div>
        </div>
        <a-space>
        <a-button class="editable-add-btn" @click="addUser" style="margin-bottom: 8px" type="primary" :hidden="loggedInCalendar">Add Person</a-button>
        </a-space>
        <a-table 
            :columns="userColumns" 
            :data-source="userData" 
            size="small"
            :pagination=false
            bordered
            :style="{
                marginLeft:'20px',
                marginRight: '30px',
            }"
            >
            <template #operation="{ record }">
            <div class="editable-row-operations">
                <a @click="onEditUser(record.value)" :hidden="loggedInCalendar">Edit</a>
                <Popconfirm
                    v-if="userData.length"
                    title="Sure to delete?"
                    @confirm="onDeleteUser(record)"
                >
                    <a :hidden="loggedInCalendar">Delete</a>
                </Popconfirm>
            </div>
            </template>
            <template v-for="col in ['email', 'name', 'phone', 'sms', 'value']" #[col]="{ text, record }" :key="col">
            <div>
                <Input
                    v-if="editableData[record.value]"
                    v-model:value="editableData[record.value][col]"
                    style="margin: -5px 0; width:100%"
                />
                <template v-else>
                    {{ text }}
                </template>
            </div>
            </template>
        </a-table>

        <a-modal v-model:visible="visibleSelectUser" title="Select Person" @ok="okSelectUser">
            Day: 
            <a-select
            v-model:value="selectedUserDay"
            style="width: 200px"
            :options="userData"
            optionLabelProp="name">
            </a-select>
            <br/>
            Night: 
            <a-select
            v-model:value="selectedUserNight"
            style="width: 200px"
            :options="userData"
            optionLabelProp="name">
            </a-select>
        </a-modal>
        <a-modal v-model:visible="visibleEditUser" title="Edit Person" @ok="okEditUser">
            <a-form :model="editableUserData" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Shortname" >
                    <a-input v-model:value="editableUserData.value" :disabled="!editableUserData.new_user" />
                </a-form-item>
                <a-form-item label="Name">
                    <a-input v-model:value="editableUserData.name" />
                </a-form-item>
                <a-form-item label="Phone">
                    <a-input v-model:value="editableUserData.phone" />
                </a-form-item>
                <a-form-item label="Additional SMS">
                    <a-input v-model:value="editableUserData.sms" />
                </a-form-item>
                <a-form-item label="E-Mail">
                    <a-input v-model:value="editableUserData.email" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
    </ConfigProvider>
</template>

<script>
// @ is an alias to /src
//import ModuleServices from '@/services/ModuleServices.js'
import { ConfigProvider, Calendar, Input, Popconfirm } from 'ant-design-vue';
import dayjs from 'dayjs';
import 'dayjs/locale/de'
import deDE from 'ant-design-vue/lib/locale-provider/de_DE';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);
dayjs.updateLocale('de', {
    weekStart: 1
});
//dayjs.locale("de");
import { cloneDeep } from 'lodash-es';
import ModuleServices from "@/services/ModuleServices.js";
/* eslint-disable vue/no-unused-components */
import { StarOutlined } from '@ant-design/icons-vue';
import { authComputed } from '../vuex/helpers.js'

export default {
  name: 'NotificationCalendar',
  components: {
    Calendar,
    Input,
    Popconfirm,
    StarOutlined,
    ConfigProvider
  },
    props: {
        listData: {
            type: Object
        },
        userData: {
            type: Object
        }
    },
  data() {
    return {
        deDE,
        noChanges: true,
      calendarValue: dayjs(),
      ignoreNextSelectDate: false,
      editableData: {},
      visibleSelectUser: false,
      visibleEditUser: false,
      editableUserData: {},
      selectedUserDay: '',
      selectedUserNight: '',
      selectedUserNignt: '',
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 34,
      },
      listDatax: {
        '2022-03-30': {
          day: 'FZ',
          night: 'TS'
        },
        '2022-03-31': {
          day: 'HME',
          night: ''
        }
      },
      userDatax: [
          {
              value: 'HME',
              name: 'Hubert Merkle',
              phone: '+4915115943662',
              sms: '',
              email: 'hme@eco-stor.com'
          }, 
          {
              value: 'FZ',
              name: 'Florian Zeller',
              phone: '+4915115943663',
              sms: '',
              email: 'fz@eco-stor.com'
          }
      ],
      userColumns: [
        {
            title: 'value',
            dataIndex: 'value',
            key: "value",
            slots: {
                customRender: 'value',
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: "name",
            slots: {
                customRender: 'name',
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: "phone",
            slots: {
                customRender: 'phone',
            },
        },
        {
            title: 'SMS (extra)',
            dataIndex: 'sms',
            key: "sms",
            slots: {
                customRender: 'sms',
            },
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: "email",
            slots: {
                customRender: 'email',
            },
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            slots: {
                customRender: 'operation',
            },
        }
      ]
    }
  },
  mounted() {
    console.log(dayjs.locale())
    console.log(this.calendarValue.locale())
  },
  computed: {
    ...authComputed
  },
  methods: {
    onSendDataOfDay(data) {
        let that = this;
        ModuleServices.putNotificationCalendarDataOfDay(data)
            .then(response => {
                console.log("OK: " + response);
            })
            .catch(error => {
                console.log("Error: " + error);
                that.openNotificationWithIcon("error", 'Error sending data!', 'Error while sending data to backend: ' + error);
                return false;
            })
        this.noChanges = true;
        return true;
    },
    getListData(value) {
        let result = "";
        let index = value.format("YYYY-MM-DD")
        let day = "";
        let night = "";
        if (index in this.listData) {
            if ("day" in this.listData[index]) {
                day = this.listData[index]["day"];
            }
            if ("night" in this.listData[index]) {
                night = this.listData[index]["night"]
            }
        }
        if (day == "") day = "-";
        if (night == "") night = "-";
        result = "<t><img class='daynighticon'; src='./Day.png'>" + day + "</t>";
        result += "<br/>";
        result += "<t><img class='daynighticon'; src='./Night.png'>" + night + "</t>"
        return result;
    },

    onEditUser(value) {
      console.log("Edit User " + value);
      this.editableUserData = cloneDeep(this.userData.filter(item => value === item.value)[0]);
      this.editableUserData["new_user"] = false;
      this.visibleEditUser = true;
    },
    onCancel(value) {
      console.log("Cancel " + value);
      delete this.editableData[value];
    },
    addUser() {
      console.log("Add User")
      this.editableUserData = { value:"", name:"", phone:"", email:"", sms:"", new_user: true}
      this.visibleEditUser = true;
    },
    onDeleteUser(record) {
        console.log("Delete " + record)
        var that = this;
        ModuleServices.putNotificationCalendarUserAction("delete", record)
            .then(response => {
                console.log("OK: " + response);
            })
            .catch(error => {
                console.log("Error: " + error);
                that.openNotificationWithIcon("error", 'Error sending data!', 'Error while sending data to backend: ' + error);
            })
        //this.onReloadData();
    },
    okEditUser() {
        // Edit data of User
        this.visibleEditUser = false;
        var that = this;
        ModuleServices.putNotificationCalendarUserAction("edit", this.editableUserData)
            .then(response => {
                console.log("OK: " + response);
            })
            .catch(error => {
                console.log("Error: " + error);
                that.openNotificationWithIcon("error", 'Error sending data!', 'Error while sending data to backend: ' + error);
            })
        //this.onReloadData();        
    },
    onPanelChange(date, mode) {
        console.log(mode + " - " + date.format("YYYY-MM-DD"))
        this.ignoreNextSelectDate = true
    },
    onSelectDate(   ) {
        // called by click in calendar
        if (this.ignoreNextSelectDate) {
            this.ignoreNextSelectDate = false;
            return;
        }
        // create index for list userData
        let index = this.calendarValue.format("YYYY-MM-DD")
        if (index in this.listData) {
            if ("day" in this.listData[index]) {
                this.selectedUserDay = this.listData[index]['day']
            } else {
                this.selectedUserDay = "";
            }
            if ("night" in this.listData[index]) {
                this.selectedUserNight = this.listData[index]['night']
            } else {
                this.selectedUserNight = "";
            }
        } else {
            this.selectedUserDay = "";
            this.selectedUserNight = "";
        }
        // show dialog
        this.visibleSelectUser = true;
    },
    okSelectUser() {
        // add users into list userData
        let index = this.calendarValue.format("YYYY-MM-DD")
        // close dialog
        this.visibleSelectUser = false;
        this.noChanges = false;
        
        var data = {
            date: index,
            day: this.selectedUserDay,
            night: this.selectedUserNight
        }
        if (this.onSendDataOfDay(data)) {
            //this.onReloadData()
        }
    },
    openNotificationWithIcon(type, message, description, duration=5) {
        const key = this.module_name;
        this.$notification[type]({
            key,
            message: message,
            description: description,
            duration: duration
        });
    },
    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    },
    onDownload() {
        // Start file download.
        let data = "";
        for (let index in this.listData) {
            data += index;
            data += ";";
            data += this.listData[index].day;
            data += ";";
            data += this.listData[index].night;
            data += "\r\n";
        }
        this.download("calendar.csv", data);
    },
    onAddWorkingDays() {
        console.log("Add working days for " + this.calendarValue.format("YYYY-MM"))
        var that = this;
        ModuleServices.putNotificationCalendarUserAction("add_working_days", { month: this.calendarValue.format("YYYY-MM") })
            .then(response => {
                console.log("OK: " + response);
            })
            .catch(error => {
                console.log("Error: " + error);
                that.openNotificationWithIcon("error", 'Error sending data!', 'Error while sending data to backend: ' + error);
            })
        //this.onReloadData();
    }
  }
}
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

.ant-fullcalendar-fullscreen /deep/ .ant-fullcalendar-date  {
    max-height: 80px !important;
}
</style>
<style>
.daynighticon {
    width: 15px; 
    height: 15px;
    margin-right: 20px;
}
</style>