<template> 
  <div>
      <a-button v-if="loggedInEsos" type="primary" @click=onModulesNew>New Module</a-button>
      <a-modal
        title="Create new Module"
        :visible="dialogNewVisible"
        @ok="onDialogNewOk"
        :confirmLoading="confirmLoading"
        @cancel="onDialogNewCancel"
      >

        <a-form-item label="Module ID">
          <a-input-number v-model:value="newModuleId"></a-input-number>
        </a-form-item>
        <a-menu
          id="createNewModule"
          style="width: 400px"
          :openKeys="openModuleClassKeys"
          v-model:selectedKeys="selectedModuleClassKeys"
          mode="inline"
          @openChange="onOpenChange"
        >

          <a-sub-menu v-for="(group, index) in module_classes" :key="index" >
            <template #icon>
              <GroupOutlined />
            </template>
            <template #title>{{ index }}</template>
            <a-menu-item v-for="module_class in group" :key="module_class.class">
              <template #icon>
                <MediumOutlined />
              </template>
                {{ module_class.class }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-modal>

  </div>
  <div scrollable-container>
    <div v-for="(group, index) in modules" :key="index">
      <a-divider style="height: 20px; border-color: #7cb305" ><h2>{{ index }}</h2></a-divider>
      <table>
        <ConfigurationModuleCard v-for="module in group" :key="module.name" :module="module" :group="index" @update-module-list="onUpdateModuleList"/>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import ModuleServices from '@/services/ModuleServices.js'
import ConfigurationModuleCard from '@/components/ConfigurationModuleCard.vue'
import { GroupOutlined, MediumOutlined } from '@ant-design/icons-vue';
import { authComputed } from '../vuex/helpers.js'
import Utils from '../services/Utils.js';

export default {
  name: 'ConfigurationModules',
  data() {
    return {
      selectedKeys: ['1'],
      modules: null,
      dialogNewVisible: false,
      confirmLoading: false,
      module_classes: [],
      openModuleClassKeys: [],
      selectedModuleClassKeys: [],
      newModuleId: 0,
      status_text: "",
      rootSubmenuKeys: [],
      timeoutLoop: null,
    };
  },
  created() {
    console.log("ConfigurationModules");
    this.onLoadModuleList();
    console.log("Modules loaded");
  },
  unmounted() {
    if (this.timeoutLoop != null)
      clearTimeout(this.timeoutLoop);
    this.timeoutLoop = null;
  },
  components: {
    ConfigurationModuleCard, GroupOutlined, MediumOutlined
  },
  computed: {
    ...authComputed
  },
  methods: {
    onLoadModuleList() {
      ModuleServices.getModules()
        .then(response => {
          this.modules = response.data.modules
          // sort by group
          const sort = (obj) => Object.keys(obj).sort((a, b) => { if (a == "Core") return true; if (b == "Core") return false; return Utils.compare(a, b); }).reduce((acc, c) => { acc[c] = obj[c]; return acc }, {})
          this.modules = sort(this.modules)
          // sort modules by name
          for (const [key] of Object.entries(this.modules)) {
            this.modules[key] = this.modules[key].sort((a, b) => {
              return Utils.compare(Utils.getStringWithLeadingZeros(a.name, 4), Utils.getStringWithLeadingZeros(b.name, 4));
            });
          }
          for (const [key] of Object.entries(this.modules)) {
            this.rootSubmenuKeys.push(key)
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        })
      this.timeoutLoop = setTimeout(this.onLoadModuleList, 2000);
    },
    onModulesNew() {
      console.log("Insert new Module")
      ModuleServices.getModuleClasses()
        .then(response => {
          this.module_classes = response.data.module_classes
        })
        .catch(error => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        })
      this.dialogNewVisible = true
    },
    onDialogNewOk() {
      console.log("NEW Module: " + this.selectedModuleClassKeys[0] + "_" + this.newModuleId);
      ModuleServices.createNewModule(this.selectedModuleClassKeys[0], this.newModuleId)
      .then(response =>  {
        this.status_text = response.data.status.text
        if (response.data.status.value == 0) {
          this.$notification['success']({
            message: 'Create new module',
            description: this.status_text
          })
        } else {
          this.$notification['warning']({
            message: 'Create new module',
            description: this.status_text
          });
        }
        console.log(this.status);
        this.onLoadModuleList();
      })
      this.dialogNewVisible = false;
      // reload module list
      this.onLoadModuleList()
    },
    onDialogNewCancel() {
      console.log("NEW Cancel");
      this.dialogNewVisible = false;
    },
    onOpenChange(openKeys) {
      console.log("onOpenChange");
      console.log(openKeys);
      console.log(this.openModuleClassKeys);
      const latestOpenKey = openKeys.find(key => this.openModuleClassKeys.indexOf(key) === -1);
      console.log(latestOpenKey);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openModuleClassKeys = openKeys;
      } else {
        this.openModuleClassKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onUpdateModuleList() {
      console.log("Update Module List");
      this.onLoadModuleList();
    }
  },
}
</script>

