<template>
    <div class="edit-param-bitmask">
      <span style="font-weight:bold">{{ param.name }}</span> (Bitmask)
        {{ getDescription(param) }}
        <InputNumber v-model:value="param_temp.value"></InputNumber>
        <div v-for="(group, index) in bitlist" :key="index" :header="index" style="margin-left:100px">
          <Switch v-model:checked="bitlist[index]" :change="bitChanged()" style="margin-right:20px"/>{{getBitName(index)}}
        </div>
    </div>
</template>

<script>
import { InputNumber } from 'ant-design-vue';
import { Switch } from 'ant-design-vue';

export default {
  data() {
    return {
      param_temp: {},
      bitlist: []
    }
  },
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: { 
    InputNumber,
    Switch
  },
  created() {
    this.param_temp = this.param; 
    this.bitlist = [];
    console.log("Value: " + this.param_temp.value);
    
    for (var j = 0; j < Object.keys(this.param_temp.ui_infos).length; j++) {
      this.bitlist.push(false);
    }
    for (var i = 0; i < this.bitlist.length; i++) {
      var id = Object.keys(this.param_temp.ui_infos)[i];
      this.bitlist[i] = (this.param_temp.value & (1 << id)) > 0;
    }
  }, 
  methods:
  {
    getDescription(param) {
      if (param.description) {
        return " ( " + param.description + " )";
      } else {
        return ""
      }
    },
    bitChanged() {
      var value = 0;
      for (var i = 0; i < this.bitlist.length; i++) {
        if (this.bitlist[i] == true) {
          var id = Object.keys(this.param_temp.ui_infos)[i];
          value = value | 1 << id;
        }
      }
      this.param_temp.value = value;
    },
    getBitName(index) {
      var id = Object.keys(this.param_temp.ui_infos)[index];
      return this.param_temp.ui_infos[id].name;
    }
  }
}
</script>

<style scoped>
.edit-param-bitmask {
  padding: 2px;
  width: 100%;
  height: 100%;
  margin-bottom: 2px;
}

</style>