ä<template>

<svg class="svg_with_border" 
  viewBox="0 0 141.5 151.5"
  xmlns="http://www.w3.org/2000/svg"
  :width="width" 
  :height="height" 
>
<path id="white_back" class="st0" d="M134,150.8H8.1c-3.9,0-7-3.1-7-7V7.9c0-3.9,3.1-7,7-7H134c3.9,0,7,3.1,7,7v135.9
	C141,147.7,137.8,150.8,134,150.8z"/>
<text transform="matrix(1 0 0 1 46.5635 59.9764)" class="st1 st2 st3">{{t.Frequency}}</text>
<text transform="matrix(1 0 0 1 45.8476 45.869)" class="st4"><tspan x="0" y="0" class="st2 st5">{{round(p.vFrequency, 3)}}</tspan><tspan class="st1 st2 st3"> Hz</tspan></text>
<g id="icon_hz1">
	<path id="box" class="st6" d="M30.9,42.6c0-4.6,0,1.9,0-2.7c0-3.2-1.7-4.9-4.9-4.9c-3.6,0-5.5,0-9.2,0c-2.7,0-4.5,1.8-4.5,4.5
		c0,9.3,0,7.5,0,16.8c0,3.1,1.6,4.8,4.7,4.8c3.7,0,5.6,0,9.3,0c2.8,0,4.5-1.8,4.5-4.6C30.9,51.9,30.9,47.3,30.9,42.6L30.9,42.6z
		M29.2,56.6c0,1.9-1,2.9-2.9,2.9c-3.7,0-5.7,0-9.4,0c-1.9,0-2.8-1-2.8-2.9c0-9.3,0-7.6,0-16.9c0-1.9,1-2.9,2.9-2.9
		c3.6,0,5.6,0,9.2,0c2.1,0,3,0.9,3,3c0,4.6,0-1.8,0,2.9l0,0C29.2,47.3,29.2,51.9,29.2,56.6z"/>
	<g id="qAHxZ1.tif">
		<g>
			<path class="st6" d="M22.4,48.2c0,1.8,0,3.6,0,5.4c0,0.4-0.3,0.7-0.6,0.8c-0.4,0.1-0.8-0.3-0.8-0.7c0-0.7,0-1.4,0-2.1
				c0-2.9,0-5.8,0-8.7c0-0.4,0.2-0.7,0.6-0.8c0.4-0.1,0.8,0.3,0.8,0.7c0,0.7,0,1.4,0,2.2C22.4,46,22.4,47.1,22.4,48.2
				C22.4,48.2,22.4,48.2,22.4,48.2z"/>
			<path class="st6" d="M25,48.2c0,1.2,0,2.3,0,3.5c0,0.6-0.6,1-1.1,0.7c-0.3-0.1-0.4-0.4-0.4-0.7c0-0.6,0-1.2,0-1.8
				c0-1.7,0-3.3,0-5c0-0.1,0-0.3,0-0.4c0.1-0.4,0.4-0.6,0.8-0.6c0.3,0,0.6,0.3,0.6,0.7c0,0.5,0,1,0,1.5C25,46.8,25,47.5,25,48.2
				C25,48.2,25,48.2,25,48.2z"/>
			<path class="st6" d="M18.3,48.1c0-1.2,0-2.3,0-3.5c0-0.5,0.3-0.8,0.8-0.8c0.4,0,0.7,0.4,0.7,0.8c0,1.9,0,3.9,0,5.8
				c0,0.4,0,0.8,0,1.2c0,0.4-0.2,0.7-0.6,0.8c-0.3,0.1-0.7-0.1-0.8-0.4c0-0.1-0.1-0.2-0.1-0.4C18.3,50.5,18.3,49.3,18.3,48.1
				C18.3,48.1,18.3,48.1,18.3,48.1z"/>
			<path class="st6" d="M17,48.1c0,0.8,0,1.5,0,2.3c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.8-0.3-0.8-0.7c0-0.4,0-0.9,0-1.3
				c0-1.1,0-2.2,0-3.3c0-0.4,0.3-0.7,0.6-0.8c0.5-0.1,0.8,0.2,0.8,0.7c0,0.6,0,1.3,0,1.9C17,47.9,17,48,17,48.1
				C17,48.1,17,48.1,17,48.1z"/>
			<path class="st6" d="M26.3,48.1c0-0.8,0-1.5,0-2.3c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.8c0,1.1,0,2.2,0,3.4
				c0,0.4,0,0.8,0,1.2c0,0.6-0.6,0.9-1.1,0.7c-0.2-0.2-0.4-0.4-0.4-0.7C26.3,49.7,26.3,48.9,26.3,48.1z"/>
		</g>
	</g>
</g>
<path id="blue_back" class="st6" d="M141,20H1c0,0,0-4,0-8.8V9.6c0-4.9,2.2-8.8,8.1-8.8h123.8c5.9,0,8.1,4,8.1,8.8v1.5
	C141,16.1,141,20,141,20z"/>
<text transform="matrix(1 0 0 1 11.3232 14.7225)" class="st7 st2 st8">{{t.GridFrequency}}</text>

</svg>
</template>

<script>
export default {
  name: 'PlantFrequency',
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    p: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  methods: {
    get_p_status_text() {
      if (this.p.vPlantStatus) {
        return "active";
      } else {
        return "inactive";
      }
    },
    get_p_status_color() {
      if (this.p.vPlantStatus) {
        return "RGB(0, 210, 0)";
      } else {
        return "red";
      }
    },
    get_p_usecases_vneid_visibility(active) {
      if (this.p.vUsecaseVndIdStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_fcr_visibility(active) {
      if (this.p.vUsecaseFcrStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    round(value, count) {
      try {
        return value.toFixed(count);
      } catch {
        return "---"
      }
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}

#p_status {
  fill: v-bind('get_p_status_color()') !important;
}

#p_vneid_false {
  visibility: v-bind('get_p_usecases_vneid_visibility(false)') !important;
}
#p_vneid_true {
  visibility: v-bind('get_p_usecases_vneid_visibility(true)') !important;
}
#p_fcr_false {
  visibility: v-bind('get_p_usecases_fcr_visibility(false)') !important;
}
#p_fcr_true {
  visibility: v-bind('get_p_usecases_fcr_visibility(true)') !important;
}

.st0{fill:#FFFFFF;stroke:#969696;stroke-width:0.5;stroke-miterlimit:10;}
.st1{fill:#939393;}
.st2{font-family:'Verdana';}
.st3{font-size:9px;}
.st4{enable-background:new    ;}
.st5{font-size:16px;}
.st6{fill:#254965;}
.st7{fill:#FFFFFF;}
.st8{font-size:11px;}

</style>