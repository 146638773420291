<template>
  <td style="width:30%">
    <span style="font-weight:bold">{{ config.name }}</span> (IP Address) {{getDescription(config)}}
  </td>
  <td>
    <Form class="edit-config-ip"
      name="edit-config-ip"
      :model="config_temp"
      :rules="rules"
      v-bind="layout"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
      style="height:32px" >
        <FormItem has-feedback name="value">
          <Input v-model:value="config_temp.value"></Input>
        </FormItem>
    </Form>
  </td>
</template>

<script>
import { Input } from 'ant-design-vue';
import { Form, FormItem } from 'ant-design-vue';

export default {
  data() {
    return {
      config_temp: {},
      rules: {
        value: [
          {
            required: true,
            validator: this.validateValue,
            trigger: 'change',
          },
        ],
      },
      layout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 14,
        },
      }
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  components: { 
    Input,
    Form, FormItem
  },
  methods: {
    validateValue: async (rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the ip address ' + value);
      } else {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {  
          return Promise.resolve();
        }  
        return Promise.reject('Please input the ip address ' + value);
      }
    },
    handleFinish: values => {
        console.log("handleFinish", values, this.config_temp);
    },
    handleFinishFailed: errors => {
        console.log("handleFinishFailed", errors);
    },
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    }
  },
  created() {
    this.config_temp = this.config;
  }
}
</script>

<style scoped>
tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px;
  text-align: left;
}
</style>