export default{
    getBaseURL() {
        var loc = new URL(window.location);
        console.log("GetBaseURL (NEW)")
        console.log(loc);
        console.log(loc.port);
        var port = loc.port
        if (! port) {
          port = 80;
        }
        console.log("Port: " + port);
        if ((loc.port == 443) || (loc.protocol == "https:")) {
          // if Port 443 is used, api calls must be forwarded by webserver 
          return loc.protocol + "//" + loc.hostname;
        } else if ((port == 80) || (port == 8080) || (! port)) {
          return loc.protocol + "//" + loc.hostname + ":8000";
        } else {
          // if webui is called by different port than 80 or 443. call api by same port
          return loc.protocol + "//" + loc.hostname + ":" + port;
        }
    },
    parseLocaleNumber(stringNumber, locale) {
        var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
        var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

        return parseFloat(stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
        );
    },  
    setFormattedFloatString(value, unit, unit_prefix, unit_factor) {
        /*
        value: editor value
        unit: unit of variable
        unit_prefix: last given unit_prefix (k, M)
        unit_factor: last given unit_vactor (0.01, 1000, 1000000)
  
        return object contains:
          value: real value of variable (without applied factor) as Float
          unit_value: value of variable (with applied factor) as String
          unit_prefix: new prefix of unit
          unit_factor: new factor of unit
  
        If no unit prefix is given in input string, current prefix and factor will not be changed!
        */
        var result = {}
        var suffix = value.slice(-1)
        if (['W', 'A', 'var', 'V', 'Wh', 'Ah'].includes(unit)) {
          if (suffix == "k") {
            unit_factor = 1000;
            unit_prefix = "k";
          } else if (suffix == "M") {
            unit_factor = 1000000;
            unit_prefix = "M";
          } else if (suffix == " ") {
            unit_factor = 1;
            unit_prefix = " ";
          } else {
            // no change, use last unit factor and prefix!
            if (unit_factor === undefined) unit_factor = 1;
            if (unit_prefix === undefined) unit_prefix = "";
          }
        } else if (['%'].includes(unit)) {
          unit_factor = 0.01;
          unit_prefix = "";
        } else {
          unit_factor = 1;
          unit_prefix = "";
        }
        result.unit_value = value.replace(unit_prefix, "")
        result.value = this.parseLocaleNumber(result.unit_value) * unit_factor;
        result.unit_prefix = unit_prefix;
        result.unit_factor = unit_factor;
        return result;
      },
      getFormattedFloatString(value, unit) {
        var converted = false;
        var result = {}
        if (["V", "A", "Ah", "W", "Wh", "VA", "VAh", "var", "varh", "bar"].includes(unit)) {
          if (Math.abs(value) >= 10000000) {
              result.value = value / 1000000
              result.unit_prefix = "M";
              result.unit_factor = 1000000;
              converted = true;
          } else if  (Math.abs(value) >= 10000) {
              result.value = value / 1000;
              result.unit_prefix = "k";
              result.unit_factor = 1000;
              converted = true;
          }
        } else if (['%'].includes(unit)) {
          result.value = value * 100
          result.unit_prefix = "";
          result.unit_factor = 0.01;
          converted = true;
        }
        if (!converted) {
          result.unit_prefix = "";
          result.value = value;
        }
        result.value = parseFloat(result.value).toLocaleString(navigator.language, {useGrouping:false})
        return result;
      },
      getStringWithLeadingZeros(value, count) {
        var list = value.split("_");
        var result = "";
        for (var [, s] of Object.entries(list)) {
          if (result) result += "_";
          var part = parseInt(s);
          if (isNaN(part)) {
            result += s;
          } else {
            result += String(part).padStart(count, "0");
          }
        }
        return result;
      },
      compare(value1, value2) {
        if (value1 < value2) {
          return -1;
        } else if (value1 > value2) {
          return 1;
        } else {
          return 0;
        }
      },
      
      isValidPlus (topic) {
        if (topic.includes('#'))  {
          return false;   // currently we do not support topics with # as wildcard
        }
        if (!topic.includes('+') || topic.length === 1) {
          return true;
        }
        let topic_parts = topic.split("+");
        if (topic_parts.length === 1) {
          return true;
        } else if (topic_parts.length > 2) {
          return false;
        }
        if ((topic_parts[0].length > 0) && (topic_parts[0][topic_parts[0].length - 1] !== "/")) {
          return false;
        }
        if ((topic_parts[1].length > 0) && (topic_parts[1][0] !== "/")) {
          return false;
        }
        return true;
      },
      
      isValidMqtt (str) {
        if (str.length === 0) return false
        return this.isValidPlus(str)
      }
}