ä<template>

<svg class="svg_with_border" 
  viewBox="0 0 141.5 151.5"
  xmlns="http://www.w3.org/2000/svg"
  :width="width" 
  :height="height" 
>

<path id="white_back" class="st0" d="M134,150.8H8.1c-3.9,0-7-3.1-7-7V7.9c0-3.9,3.1-7,7-7H134c3.9,0,7,3.1,7,7v135.9
	C141,147.7,137.8,150.8,134,150.8z"/>
<text transform="matrix(1 0 0 1 45.4238 101.0028)" class="st1 st2 st3">{{t.PlantIdentifier}}</text>
<text transform="matrix(1 0 0 1 44.708 86.8953)" class="st2 st4">{{p.vPlantId}}</text>
<text transform="matrix(1 0 0 1 46.5635 59.9764)" class="st1 st2 st3">{{t.Location}}</text>
<text transform="matrix(1 0 0 1 45.8476 45.869)" class="st2 st4">{{p.vPlantLocation}}</text>
<g id="icon_plant">
	<path class="st5" d="M30.3,56c-0.4-0.5-0.8-0.9-1.4-1.2c-0.7-0.4-1.5-0.7-2.3-0.9c-0.4-0.1-0.6-0.1-1.1-0.2l-1.2,2.1
		c0.6,0.1,1.2,0.2,1.7,0.4c0.7,0.2,1.1,0.4,1.7,0.7c0.2,0.1,0.4,0.2,0.5,0.3c0,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.4,0.3-0.8,0.4-1.3,0.6c-0.7,0.2-1.3,0.3-2,0.4c-0.9,0.1-1.8,0.2-2.8,0.2c-0.9,0-1.9,0-2.8-0.2c-0.7-0.1-1.4-0.2-2-0.4
		c-0.5-0.1-0.9-0.3-1.3-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.6-0.3,1.3-0.5,1.9-0.6
		c0.6-0.1,0.9-0.2,1.5-0.3l-1.4-2.2c-0.5,0.1-0.7,0.1-1.1,0.2c-0.8,0.2-1.6,0.5-2.3,0.9c-0.5,0.3-1,0.7-1.4,1.2
		c-0.3,0.4-0.5,0.9-0.4,1.4c0,0.6,0.3,1.1,0.7,1.6c0.4,0.5,1,0.8,1.5,1c0.6,0.3,1.3,0.5,1.9,0.7c0.6,0.2,1.3,0.3,1.9,0.3
		c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.8,0.1,1.7,0.1,2.5,0.1c0.8,0,1.7,0,2.5-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
		c0.7-0.1,1.3-0.2,1.9-0.3c0.7-0.2,1.3-0.4,1.9-0.7c0.6-0.3,1.1-0.6,1.5-1c0.4-0.4,0.7-1,0.7-1.6C30.7,56.9,30.6,56.5,30.3,56z"/>
	<path class="st5" d="M15.2,46.6c1.3,2,2.5,4,3.8,6.1c0,0,0,0,0,0.1l0,0l0.4,0.8l1.3,2.3c0.4,0.6,0.7,1.2,1.1,1.8c0,0,0,0,0.1,0.1
		c0,0,0-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.3-0.5c2-3.3,4-6.6,6-9.9c0.3-0.5,0.6-1,0.8-1.5c0.3-0.7,0.5-1.5,0.5-2.2
		c0.1-0.9,0-1.7-0.3-2.6c-0.5-1.7-1.4-3-2.7-4.1c-1.1-0.9-2.4-1.4-3.9-1.6c-1-0.1-2,0-2.9,0.2c-1,0.3-1.9,0.7-2.7,1.4
		c-1.8,1.4-2.7,3.3-2.9,5.6c-0.1,1.1,0.1,2.2,0.5,3.3C14.9,46,15,46.3,15.2,46.6z M21.9,38.6c1.8,0,3.3,1.5,3.3,3.3
		c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3C18.6,40.1,20.1,38.7,21.9,38.6z"/>
</g>
<path id="blue_back" class="st5" d="M141,20H1c0,0,0-4,0-8.8V9.6c0-4.9,2.2-8.8,8.1-8.8h123.8c5.9,0,8.1,4,8.1,8.8v1.5
	C141,16.1,141,20,141,20z"/>
<text transform="matrix(1 0 0 1 11.3232 14.7225)" class="st6 st2 st7">{{t.Plant}}</text>

</svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    p: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  methods: {
    get_p_status_text() {
      if (this.p.status) {
        return "active";
      } else {
        return "inactive";
      }
    },
    get_p_status_color() {
      if (this.p.status) {
        return "RGB(0, 210, 0)";
      } else {
        return "red";
      }
    },
    get_p_usecases_vneid_visibility(active) {
      if (this.p.vUsecaseVndIdStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_fcr_visibility(active) {
      if (this.p.vUsecaseFcrStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}

#p_status {
  fill: v-bind('get_p_status_color()') !important;
}

#p_vneid_false {
  visibility: v-bind('get_p_usecases_vneid_visibility(false)') !important;
}
#p_vneid_true {
  visibility: v-bind('get_p_usecases_vneid_visibility(true)') !important;
}
#p_fcr_false {
  visibility: v-bind('get_p_usecases_fcr_visibility(false)') !important;
}
#p_fcr_true {
  visibility: v-bind('get_p_usecases_fcr_visibility(true)') !important;
}

	.st0{fill:#FFFFFF;stroke:#969696;stroke-width:0.5;stroke-miterlimit:10;}
	.st1{fill:#939393;}
	.st2{font-family:'Verdana';}
	.st3{font-size:9px;}
	.st4{font-size:16px;}
	.st5{fill:#254965;}
	.st6{fill:#FFFFFF;}
	.st7{font-size:11px;}

</style>