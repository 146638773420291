<template>
  <h3>{{ $store.submodule_config_name }}</h3>
  <div style="overflow:scroll; height:100%">
  <table class="subconfig_container" id="subconfig_container"></table>
  <a-button type="primary" @click=onSaveConfig>Save Config</a-button>
  </div>
</template>

<script>
// @ is an alias to /src

import { createApp } from "vue"
import router from '../router/index.js'
import EditConfigFloat from '../components/EditConfigFloat.vue';
import EditConfigInt from '../components/EditConfigInt.vue';
import EditConfigString from '../components/EditConfigString.vue';
import EditConfigBool from '../components/EditConfigBool.vue';
import EditConfigTopic from '../components/EditConfigTopic.vue';
import EditConfigIpAddress from '../components/EditConfigIpAddress.vue';
import EditConfigSubmodule from '../components/EditConfigSubmodule.vue';

export default {
  name: 'ConfigurationSubmoduleDetail',
  data() {
    return {
      moduleConfig: null
    };
  },
  mounted() {
    console.log("1");
    this.moduleConfig = this.$store.submodule_config
    console.log(2);
    console.log(document);
    console.log(this.moduleConfig)
    document.querySelector('#subconfig_container').innerHTML = '';
    if (this.moduleConfig !== undefined) {
      // eslint-disable-next-line no-unused-vars
      for (const [gkey, gvalue] of Object.entries(this.moduleConfig)) {
        console.log(gkey)
        const p_wrapper = document.createElement("p");
        p_wrapper.innerText = gkey
        document.querySelector('.subconfig_container').appendChild(p_wrapper);
        const table_wrapper = document.createElement("table");
        table_wrapper.style.width = "100%"
        table_wrapper.style.padding = "20px"
        
        document.querySelector('.subconfig_container').appendChild(table_wrapper);
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(gvalue)) {
          console.log(value);

          var comp = null;
          if (value.config_type.editor_type === "float")
            comp = createApp(EditConfigFloat, { "config": value });
          else if (value.config_type.editor_type === "int")
            comp = createApp(EditConfigInt, { "config": value });
          else if (value.config_type.editor_type === "int")
            comp = createApp(EditConfigFloat, { "config": value });
          else if (value.config_type.editor_type === "bool")
            comp = createApp(EditConfigBool, { "config": value });
          else if (value.config_type.editor_type === "topic") 
            comp = createApp(EditConfigTopic, { "config": value });
          else if (value.config_type.editor_type === "ip")
            comp = createApp(EditConfigIpAddress, { "config": value });
          else if (value.config_type.editor_type === "submodule")
            comp = createApp(EditConfigSubmodule, { "config": value });
          else
            comp = createApp(EditConfigString, { "config": value });
          const wrapper = document.createElement("tr");
          comp.mount(wrapper);
          comp.use(router);
          table_wrapper.appendChild(wrapper);
        }
      }

    }        
  },
  components: {
//    EditConfigFloat
  },
  methods: {
    onSaveConfig() {
      console.log("Save Config");
      router.go(-1);
    } 
  }
}
</script>

<style scoped>
table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}


table,
tr,
th {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 10px;
  text-align: left;
}
</style>