<template> 
  <h1>{{getModuleName()}}</h1>
  <div scrollable-container>
    <div v-for="(action, index) in moduleActions" :key="index">
      <!--<a-button type="primary" @click="onActionCreate(index)">{{index}}</a-button>-->
      <ModuleAction :action_name="index" :action_params="action.params" :action_confirm="action.confirm" :module_name="moduleName" :action_non_blocking="action.non_blocking">laskdfjlas</ModuleAction>
    </div>
  </div>
  <a-modal 
      v-model:visible="actionVisible" 
      v-model:title=currentActionName 
      ok-text="Send Action"
      @ok="onActionSend(currentActionName)">
    <div class="modal_container" id="modal_container"></div>
  </a-modal>
</template>

<script>
// @ is an alias to /src


import { createApp } from "vue"
//import router from '../router/index.js'
import ModuleServices from '@/services/ModuleServices.js'
import EditParamFloat from '../components/EditParamFloat.vue';
import EditParamInt from '../components/EditParamInt.vue';
import EditParamBool from '../components/EditParamBool.vue';
import ModuleAction from '@/components/ModuleAction.vue'

export default {
  name: 'VariablesModuleDetail',
  data() {
    return {
      moduleActions: null,
      moduleName: "",
      actionVisible: false,
      currentActionName: ""
    };
  },
  mounted() {
    this.moduleName = this.$store.modules_variables_name;
    ModuleServices.getModuleActions(this.moduleName)
      .then(response => {
        this.moduleActions = response.data.actions;
      })
      .catch(error => {
        if (error.response.status == 422) {
          this.$router.push("/login");
        }
      })
  },
  components: {
    ModuleAction
  },
  methods: {
    getModuleName() {
      return this.moduleName.replace("EsosModule", "");
    },
    onActionCreate(action_name) {
      this.actionVisible = true;
      this.currentActionName = action_name
      if (this.moduleActions !== undefined) {
            var params_container = null;
            params_container = document.querySelector('.modal_container');
            var delay = 1
            // open Modal dialog with small delay to ensure, that .modal_container is still loaded before querySelector() will be called
            setTimeout(() => {
              params_container = document.querySelector('.modal_container');
              if (params_container) {
                params_container.innerHTML = '';
                var action = this.moduleActions[action_name];
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(action.params)) {
                  var comp = null;
                  if (value.param_type.editor_type === "float")
                    comp = createApp(EditParamFloat, { "param": value });
                  else if (value.param_type.editor_type === "int")
                    comp = createApp(EditParamInt, { "param": value });
                  else if (value.param_type.editor_type === "bool")
                    comp = createApp(EditParamBool, { "param": value });
                  
                  if (comp != null) {
                    const wrapper = document.createElement("div");
                    comp.use(this.$router);
                    comp.use(this.$store)
                    comp.mount(wrapper);
                    params_container.appendChild(wrapper);
                  }
                }
              } 
            }, delay);
      }



    },

    onActionSend(action) {
      ModuleServices.sendModuleAction(this.moduleName, action, this.moduleActions[action]);
      this.actionVisible = false
    }
  }
}
</script>
