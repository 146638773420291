<template>
      <td style="width:30%">
          <span style="font-weight:bold">{{ config.name }}</span> (String) {{getDescription(config)}}
          {{ calendarValue.format("YYYY-MM-DD")}}
      </td>
      <td>
        <div
:style="{
      display: 'inline-block',
      width: '800px',
      marginLeft: '20px',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
    }">
        <Calendar v-model:value="calendarValue" locale="de_DE">
        <template #dateCellRender="{ current: value }">
          <ul class="events">
            <li v-for="item in getListData(value)" :key="item.content">
              <Badge :color="item.color" :text="item.content" />
            </li>
          </ul>
        </template>
        </Calendar>
        </div>
      </td>
</template>

<script>
import { Calendar, Badge } from 'ant-design-vue';
import moment from 'moment';
moment.locale('de-DE');


export default {
  data() {
    return {
      calendarValue: moment(),
      config_temp: {},
      listData: {
        '2022-03-30': {
          day: 'FZ',
          night: 'TS'
        },
        '2022-03-31': {
          day: 'HME',
          night: 'MM'
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    } 
  },
  components: { 
    Calendar,
    Badge
  },
  created() {
    this.config_temp = this.config;
  },
  methods: {
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    },
    getListData(value) {
      console.log(value.unix());
      let result = [];
      let index = value.format("YYYY-MM-DD")
      if (index in this.listData) {
        if ("day" in this.listData[index]) {
          result.push({ color:'orange', content:this.listData[index]["day"]})
        } else {
          result.push({ color:'orange', content:"---" })
        }
        if ("night" in this.listData[index]) {
          result.push({ color:'blue', content:this.listData[index]["night"]})
        } else {
          result.push({ color:'blue', content:"---" })
        }
      } else {
          result.push({ color:'orange', content:"---" })
          result.push({ color:'blue', content:"---" })
      }
      return result;
    }
  }
}
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px; 
  text-align: left;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>