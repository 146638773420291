<template>
  <svg class="svg_with_border" 
    viewBox="0 0 141.5 151.5"
    xmlns="http://www.w3.org/2000/svg"
    :width="width" 
    :height="height" 
  >
  <path id="white_back" class="st0" d="M134,150.8H8.1c-3.9,0-7-3.1-7-7V7.9c0-3.9,3.1-7,7-7H134c3.9,0,7,3.1,7,7v135.9
    C141,147.7,137.8,150.8,134,150.8z"/>
  <text transform="matrix(1 0 0 1 45.5719 101.0028)" class="st1 st2 st3">{{t.PlantOnOff}}</text>
  <text transform="matrix(1 0 0 1 44.8561 86.8953)" class="st2 st4">{{get_p_control_text()}}</text>
  <!--
  <g id="p_off" @click="dialogStartVisible=true">
    <path id="red_frame" class="st5" d="M31.1,75.8c0.3,0,0.7,0,1,0.1c1.6,0.3,2.9,1.1,3.9,2.3c1.3,1.6,1.8,3.4,1.4,5.4
      c-0.3,1.5-1,2.8-2.3,3.8c-1.2,1-2.7,1.5-4.2,1.6c-5.9,0-11.8,0-17.7,0c-0.3,0-0.5,0-0.8,0c-0.8-0.1-1.6-0.3-2.3-0.7
      c-1.2-0.6-2.1-1.5-2.7-2.7c-0.6-1.2-0.9-2.4-0.8-3.7c0.1-0.9,0.3-1.7,0.7-2.5c0.5-1,1.2-1.8,2.2-2.4c1-0.7,2.2-1.1,3.5-1.1
      c3,0,6,0,9.1,0C25,75.8,28,75.8,31.1,75.8z M31.1,87.3c1.1,0,2.1-0.4,2.9-1.1c0.7-0.6,1.1-1.2,1.5-2c0.2-0.4,0.3-0.9,0.3-1.3
      c0-0.6,0-1.2-0.2-1.8c-0.4-1.2-1.1-2.1-2.1-2.8c-0.9-0.6-1.8-0.8-2.9-0.7c-1.5,0-3,0-4.4,0c-1.3,0-2.7,0-4,0c-1.4,0-2.8,0-4.2,0
      c-1.4,0-2.8,0-4.2,0c-0.6,0-1.1,0-1.7,0.1c-0.8,0.1-1.5,0.5-2.1,1c-0.9,0.8-1.5,1.7-1.7,2.8c-0.2,1-0.1,2,0.4,3
      c0.4,0.9,1.1,1.7,2,2.2c0.7,0.4,1.5,0.7,2.3,0.7c3,0,6,0,9.1,0C25,87.3,28.1,87.3,31.1,87.3z"/>
    <path id="red_point" class="st5" d="M13.6,85.9c1.8,0.1,3.8-1.6,3.6-3.9c-0.1-1.7-1.7-3.4-3.8-3.3c-1.8,0.1-3.5,1.6-3.4,3.9
      C10.1,84.5,11.7,86,13.6,85.9z"/>
  </g>
  <g id="p_on" @click="dialogStopVisible=true">
    <path id="green_frame" class="st6" d="M22,75.8c3,0,6,0,9.1,0c1.3,0,2.4,0.4,3.5,1.1c0.9,0.6,1.7,1.4,2.2,2.4
      c0.4,0.8,0.7,1.6,0.7,2.5c0.1,1.3-0.2,2.6-0.8,3.7c-0.6,1.2-1.5,2-2.7,2.7c-0.7,0.4-1.5,0.6-2.3,0.7c-0.3,0-0.5,0-0.8,0
      c-5.9,0-11.8,0-17.7,0c-1.6,0-3-0.5-4.2-1.6c-1.2-1-2-2.3-2.3-3.8c-0.4-2,0.1-3.8,1.4-5.4c1-1.2,2.3-2,3.9-2.3
      c0.3-0.1,0.7-0.1,1-0.1C15.9,75.8,18.9,75.8,22,75.8z M22,87.3c3,0,6,0,9.1,0c0.8,0,1.6-0.3,2.3-0.7c0.9-0.5,1.5-1.2,2-2.2
      c0.4-1,0.5-2,0.4-3c-0.2-1.1-0.8-2.1-1.7-2.8c-0.6-0.5-1.3-0.9-2.1-1c-0.6-0.1-1.1-0.1-1.7-0.1c-1.4,0-2.8,0-4.2,0
      c-1.4,0-2.8,0-4.2,0c-1.3,0-2.7,0-4,0c-1.5,0-3,0-4.4,0c-1,0-2,0.2-2.9,0.7c-1,0.7-1.7,1.6-2.1,2.8c-0.2,0.6-0.2,1.2-0.2,1.8
      c0,0.5,0.1,0.9,0.3,1.3c0.3,0.8,0.8,1.5,1.5,2c0.8,0.7,1.8,1.1,2.9,1.1C15.9,87.3,18.9,87.3,22,87.3z"/>
    <path id="green_point" class="st6" d="M30.4,85.9c-1.8,0.1-3.8-1.6-3.6-3.9c0.1-1.7,1.7-3.4,3.8-3.3c1.8,0.1,3.5,1.6,3.4,3.9
      C33.9,84.4,32.3,86,30.4,85.9z"/>
  </g>
  -->
  <text transform="matrix(1 0 0 1 46.5635 59.9764)" class="st1 st2 st3">{{t.PlantStatus}}</text>
  <text transform="matrix(1 0 0 1 45.8476 45.869)" class="st2 st4">{{get_p_status_text()}}</text>
  <circle id="p_status" class="st7" cx="22" cy="41.1" r="3.5"/>
  <path id="blue_back" class="st8" d="M141,20H1c0,0,0-4,0-8.8V9.6c0-4.9,2.2-8.8,8.1-8.8h123.8c5.9,0,8.1,4,8.1,8.8v1.5
    C141,16.1,141,20,141,20z"/>
  <text transform="matrix(1 0 0 1 11.3232 14.7225)" class="st9 st2 st10">{{t.PlantStatus}}</text>

  <g id="p_off" @click="dialogStartVisible=true">
    <rect x="10" y="73" rx="8" ry="8" width="26" height="16" style="fill:transparent;stroke-width:1.5;stroke:#BF3C3C"></rect>
    <circle cx="18" cy="81" r="5" class="st5" @mouseover="isHovering=true" @mouseleave="isHovering=false" :class="{hovering: isHovering}"> </circle>
  </g>
  <g id="p_on" @click="dialogStopVisible=true">
    <rect x="10" y="73" rx="8" ry="8" width="26" height="16" style="fill:transparent;stroke-width:1.5;stroke:#10933F"></rect>
    <circle cx="28" cy="81" r="5" class="st6" @mouseover="isHovering=true" @mouseleave="isHovering=false" :class="{hovering: isHovering}"> </circle>
  </g>
  </svg>
  <a-modal v-model:visible="dialogStartVisible" :title="t.PlantOnOff" @ok="handleOkStart">
    <p>{{t.PlantControlStartQuestion}}</p>
  </a-modal>
  <a-modal v-model:visible="dialogStopVisible" :title="t.PlantOnOff" @ok="handleOkStop">
    <p>{{t.PlantControlStopQuestion}}</p>
  </a-modal>
</template>

<script>

import ModuleServices from '@/services/ModuleServices.js'

export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    p: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  data() {
    return {
      hover: false,
      dialogStartVisible: false,
      dialogStopVisible: false,
      isHovering: false,
      plantStartInProgress: false,
      plantStopInProgress: false,
    }
  },
  methods: {
    showThat() {
      console.log("Click");
    },
    handleOk() {
      console.log("Start")
    },
    get_p_status_text() {
      if (this.p.vPlantStatus) {
        return this.t.PlantStatusActive;
      } else {
        return this.t.PlantStatusInactive;
      }
    },
    get_p_control_text() {
      if (this.p.vPlantStatus) {
        return this.t.PlantControlStop;
      } else {
        return this.t.PlantControlStart;
      }
    },
    get_p_status_color() {
      if (this.p.vPlantStatus) {
        return "#188C47";
      } else {
        return "#BF3C3C";
      }
    },
    get_p_status_visibility(active) {
      if (this.p.vPlantStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_vneid_visibility(active) {
      if (this.p.vUsecaseVndIdStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_fcr_visibility(active) {
      if (this.p.vUsecaseFcrStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    handleOkStart() {
      console.log("Start Plant");
      ModuleServices.sendModuleAction("EsosModulePlantStatus_1", "Start Plant", {});
      this.dialogStartVisible = false;
      const key = 'updateableStart';
      this.$notification['info']({
        key,
        description: 'Plant start is in progress',
        message: "Start Plant",
        duration: 15
      })
      this.plantStartInProgress = true
      setTimeout(() => {
        if (this.p.vPlantStatus) {
          // Plant started successfully
          this.$notification['success']({
            key,
            description: 'Plant started successfully!',
            message: "Start Plant"
          })
        } else {
          // Plant did not start
          this.$notification['error']({
            key,
            description: 'Plant did not start successfully!',
            message: "Start Plant"
          })
        }
        this.plantStartInProgress = false;
      }, 60000);


    },
    handleOkStop() {
      console.log("Stop Plant");
      ModuleServices.sendModuleAction("EsosModulePlantStatus_1", "Stop Plant", {});
      this.dialogStopVisible = false;
      const key = 'updateableStop';
      this.$notification['info']({
        key,
        description: 'Plant stop is in progress',
        message: "Stop Plant",
        duration: 15,
      })
      this.plantStopInProgress = true
      setTimeout(() => {
        if (!this.p.vPlantStatus) {
          // Plant stopped successfully
          this.$notification['success']({
            key,
            description: 'Plant stopped successfully!',
            message: "Stop Plant"
          })
        } else {
          // Plant did not stop
          this.$notification['error']({
            key,
            description: 'Plant did not stop successfully!',
            message: "Stop Plant"
          })
        }
        this.plantStopInProgress = false;
      }, 60000);
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}

#p_status {
  fill: v-bind('get_p_status_color()') !important;
}

#p_off {
  visibility: v-bind('get_p_status_visibility(false)') !important;
  cursor: pointer;
}
#p_on {
  visibility: v-bind('get_p_status_visibility(true)') !important;
  cursor: pointer;
}
#p_vneid_false {
  visibility: v-bind('get_p_usecases_vneid_visibility(false)') !important;
}
#p_vneid_true {
  visibility: v-bind('get_p_usecases_vneid_visibility(true)') !important;
}
#p_fcr_false {
  visibility: v-bind('get_p_usecases_fcr_visibility(false)') !important;
}
#p_fcr_true {
  visibility: v-bind('get_p_usecases_fcr_visibility(true)') !important;
}

  .st0{fill:#FFFFFF;stroke:#969696;stroke-width:0.5;stroke-miterlimit:10;}
	.st1{fill:#939393;}
	.st2{font-family:'Verdana';}
	.st3{font-size:9px;}
	.st4{font-size:16px;}
	.st5{fill:#BF3C3C;}
	.st6{fill:#10933F;}
	.st7{fill:#E0E0E0;}
	.st8{fill:#254965;}
	.st9{fill:#FFFFFF;}
	.st10{font-size:11px;}
</style>