<template>
    <h1>Version Info</h1>

    <div class="notiList" ref="notiList" style="height:100%">
    <a-table 
        class="ant-table-striped"
        :dataSource="version_info.Plants" 
        :row-key="record => record.Id"
        :loading="loading"
        :columns="columns" 
        :pagination=false
        @change="handleTableChange"
        size="small">

        <template #headerCell="{ column }">
            <span>
                <h1>{{ column.title }}</h1>
            </span>
        </template>

        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'ImOk'">
            <span>
                <a-tag v-if="record.ImOk" color="#00aa00">
                    <template #icon>
                        <check-circle-outlined />
                    </template>
                    OK
                </a-tag>
                <a-tag v-else color="#cc0000">
                    <template #icon>
                        <warning-outlined />
                    </template>
                    Error
                </a-tag>
            </span>
        </template>
        <template v-if="column.key === 'ImActive'">
            <span>
                <a-tag v-if="record.ImActive" color="#0000cc">
                    <template #icon>
                        <check-circle-outlined />
                    </template>
                    Active
                </a-tag>
                <a-tag v-else color="#aaaacc">
                    <template #icon>
                        <warning-outlined />
                    </template>
                    Inactive
                </a-tag>
            </span>
        </template>
        <template v-if="column.key === 'ImDefault'">
            <span>
                <a-tag v-if="record.ImDefault" color="#00aaaa">
                    <template #icon>
                        <check-circle-outlined />
                    </template>
                    Default
                </a-tag>
                <a-tag v-else color="#ccaa00">
                    <template #icon>
                        <warning-outlined />
                    </template>
                    Redundancy
                </a-tag>
            </span>
        </template>
        <template v-if="column.key === 'LastTimestamp'">
            <span>
                {{  getDateFromUnixTimestamp(record.LastTimestamp) }}
            </span>
        </template>
        <template v-if="column.key === 'LastVersionUpdate'">
            <span>
                {{  getDateFromUnixTimestamp(record.LastVersionUpdate) }}
            </span>
        </template>
        </template>

        
        <template #expandedRowRender="{ record }">
        <p style="margin: 0" >
            <a-table 
            :dataSource="record.VersionHistory" 
            :columns="historyColumns"
            :transformCellText="({text, column, record, index}) => onTransformHistoryText(text, column, record, index)"
            :pagination=false
            size="small"/>
        </p>
        </template>
    
    </a-table>
        <a-button @click="download()">Download</a-button>
    </div>




</template>
  
<script>
import ModuleServices from "@/services/ModuleServices.js";
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons-vue';

export default {
    name: 'VersionInfo',
    data() {
        return {
            loading: false,
            tableFilters: {},
            columns: [
                { 
                    title: 'Plant-Id',
                    dataIndex: 'PlantId',
                    key: 'PlantId',
                    ellipsis: true,
                    width: '90px'
                },
                { 
                    title: 'Core-Id',
                    dataIndex: 'PlantCoreId',
                    key: 'CoreId',
                    ellipsis: true,
                    width: '90px'
                },
                { 
                    title: 'Version',
                    dataIndex: 'Version',
                    key: 'Version',
                    ellipsis: true,
                    width: '70px'
                },
                { 
                    title: 'LastVersionUpdate',
                    dataIndex: 'LastVersionUpdate',
                    key: 'LastVersionUpdate',
                    ellipsis: true,
                    width: '100px'
                },
                { 
                    title: 'Active',
                    dataIndex: 'ImActive',
                    key: 'ImActive',
                    ellipsis: false,
                    width: '60px'
                },
                { 
                    title: 'Default',
                    dataIndex: 'ImDefault',
                    key: 'ImDefault',
                    ellipsis: false,
                    width: '60px'
                },
                { 
                    title: 'OK',
                    dataIndex: 'ImOk',
                    key: 'ImOk',
                    ellipsis: false,
                    width: '60px'
                },
                { 
                    title: 'Timeout',
                    dataIndex: 'Timeout',
                    key: 'Timeout',
                    ellipsis: false,
                    width: '70px'
                },
                { 
                    title: 'Last connect' + " (" + Intl.DateTimeFormat().resolvedOptions().timeZone + ")",
                    dataIndex: 'LastTimestamp',
                    key: 'LastTimestamp',
                    ellipsis: true,
                    width: '140px'
                },
                { 
                    title: 'Delay [s]',
                    dataIndex: 'Delay',
                    key: 'Delay',
                    ellipsis: true,
                    width: '60px'
                }

            ],
            historyColumns: [
                { 
                    title: 'Time' + " (" + Intl.DateTimeFormat().resolvedOptions().timeZone + ")",
                    dataIndex: 'timestamp',
                    key: 'timestamp',
                    width: '170px',
                    sortDirections: ['ascend'],
                    sorter: (a, b) => a.time - b.time,
                },
                { 
                    title: 'Version',
                    dataIndex: 'version',
                    key: 'version',
                    width: '250px',
                }
            ],

        }
    },
    props: {
        version_info: {
        type: Object
    }
  },
  components: {
    CheckCircleOutlined, WarningOutlined
  },
  methods: {
    handleTableChange(pag, filters, /*sorter*/) {
        this.tableFilters = filters
    },
    getDateFromUnixTimestamp(unixTimestamp) {
        var date = new Date(unixTimestamp);
        if (unixTimestamp == 0) {
            return "---";
        } else {
            return date.toLocaleString();
        }
    },
    /* eslint-disable no-unused-vars */
    onTransformHistoryText(text, column, record, index) {
        try {
            if (column.key == 'timestamp') {
                return new Date(record.timestamp).toLocaleString()
            } else
            {
                return text;
            }
        } catch {
            return ""
        }
    },
    forceFileDownload(data, title) {
      console.log(title)
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    download() {
        ModuleServices.getVersionHistoryDownload()
        .then((response) => {
            this.forceFileDownload(response.data.data, response.data.filename);
        })
        .catch((error) => {
            console.log(error);
        });
    }
  }
}






</script>