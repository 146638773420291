import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ConfigurationModules from '../views/ConfigurationModules.vue'
import NotificationCalendar from '../views/NotificationCalendar.vue'
import ConfigurationModuleDetails from '../views/ConfigurationModuleDetails.vue'
import ConfigurationSubmoduleDetails from '../views/ConfigurationSubmoduleDetails.vue'
import VariablesModules from '../views/VariablesModules.vue'
import VariablesModuleDetails from '../views/VariablesModuleDetails.vue'
import NotificationList from '../views/NotificationList.vue'
import LoggingList from '../views/LoggingList.vue'
import LoginUser from '../views/LoginUser.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'LoginUser',
    component: LoginUser
  },
  {
    path: '/configuration_modules',
    name: 'ConfigurationModules',
    component: ConfigurationModules,
    meta: { requiresAuth: true }
  },
  {
    path: '/notification_calendar',
    name: 'NotificationCalendar',
    component: NotificationCalendar,
    meta: { requiresAuth: true }
  },
  {
    path: '/configuration_module_details',
    name: 'ConfigurationModuleDetails',
    component: ConfigurationModuleDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/configuration_submodule_details',
    name: 'ConfigurationSubmoduleDetails',
    component: ConfigurationSubmoduleDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/variables_modules',
    name: 'VariablesModules',
    component: VariablesModules,
    meta: { requiresAuth: true }
  },
  {
    path: '/variables_module_details',
    name: 'VariablesModuleDetails',
    component: VariablesModuleDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/notification_list',
    name: 'NotificationList',
    component: NotificationList,
    meta: { requiresAuth: true }
  },
  {
    path: '/logging_list',
    name: 'LoggingList',
    component: LoggingList,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes
})


router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user_data')
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/')
  }
  next()
})

export default router
