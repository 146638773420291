<template>
  <td style="width:30%">
    <span style="font-weight:bold">{{ config.name }}</span> ({{ getTopicType() }}) {{getDescription(config)}}
  </td>
  <td>
    <Form class="edit-config-topic"
      name="edit-config-topic"
      :model="config_temp"
      :rules="rules"
      v-bind="layout"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"    
      style="height:32px">
        <FormItem has-feedback name="value">
          <Input v-model:value="config_temp.value"></Input>
        </FormItem>
    </Form>
  </td>
</template>

<script>
import { Input } from 'ant-design-vue';
import { Form, FormItem } from 'ant-design-vue';
import Utils from '../services/Utils.js';

export default {
  data() {
    return {
      config_temp: {},
      rules: {
        value: [
          {
            required: true,
            validator: this.validateValue,
            trigger: 'change',
          },
        ],
      },
      layout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 14,
        },
      }
    }
  },
  props: {
    config: {
      type: Object,
      required: true,
      wildcard: false
    }
  },
  components: { 
    Input,
    Form, FormItem
  },
  methods: {
    validateValue: async (rule, value) => {
      let valid = Utils.isValidMqtt(value);
      if (!valid) {
        return Promise.reject('Topic string not valid: ' + value);
      } else {
        return Promise.resolve();
      }
    },
    handleFinish: values => {
        console.log("handleFinish", values, this.config_temp);
    },
    handleFinishFailed: errors => {
        console.log("handleFinishFailed", errors);
    },
    getTopicType() {
      if (this.config_temp.config_type.wildcard)
        return "Topic with Wildcard";
      else
        return "Topic";
    },
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    }
  },
  created() {
    this.config_temp = this.config;
  }

}
</script>

<style scoped>
tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px;
  text-align: left;
}
</style>