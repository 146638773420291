<template>
  <tr class="configuration-module-card" 
      v-bind:style="[ !module.module_autostart ? {'background-color':'#FFE8E8'} : {}]">
    <td v-if="module.module_status==='RUNNING'"  style="width:5%; min-width:50px;max-width:100px">
      <CheckCircleOutlined :style="{fontSize: '32px', color: '#090'}"/>{{module.autostart_module}}
    </td>
    <td v-else-if="module.module_status=='STOPPED'"  style="width:5%; min-width:50px;max-width:100px">
      <StopOutlined  :style="{fontSize: '32px', color: '#f00'}"/>
    </td>
    <td v-else  style="width:5%; min-width:50px;max-width:100px">
      <WarningOutlined  :style="{fontSize: '32px', color: '#f00'}"/>
    </td>
    <td style="width:40%; max-width:400px; text-align:left">{{ getReducedModuleName(module.name) }} ({{ module.module_status }})</td>
    <td style="width:100%; max-width:400px">
      <a-button type="primary" @click=onEdit>
        <EditOutlined /> Edit
      </a-button>
      <a-popconfirm
        ok-text="Yes"
        cancel-text="No"
        @confirm="onConfirm"
        @cancel="onCancel"
        :disabled="module.module_status==='RUNNING'"
      >
        <template #title>
          <p>Delete {{ module.name }}?</p>
        </template>
        <a-button v-if="loggedInEsos" :disabled="module.module_status==='RUNNING'">
          <DeleteOutlined />Delete
        </a-button>
      </a-popconfirm>
      <a-popconfirm
        ok-text="Yes"
        cancel-text="No"
        @confirm="onStartConfirm"
        @cancel="onStartCancel"
        :disabled="(module.module_status!=='STOPPED') && (module.module_status!=='CONFIG_LOADED')"
      >
        <template #title>
          <p>Start {{ module.name }}?</p>
        </template>
        <a-button v-if="loggedInEsos" :disabled="(module.module_status!=='STOPPED') && (module.module_status!=='CONFIG_LOADED')">
          <PlayCircleOutlined />Start
        </a-button>
      </a-popconfirm>
      <a-popconfirm
        ok-text="Yes"
        cancel-text="No"
        @confirm="onStopConfirm"
        @cancel="onStopCancel"
        :disabled="module.module_status!=='RUNNING'"
      >
        <template #title>
          <p>Stop {{ module.name }}?</p>
        </template>
        <a-button v-if="loggedInEsos" :disabled="module.module_status!=='RUNNING'">
          <StopOutlined />Stop
        </a-button>
      </a-popconfirm>
      <a-popconfirm
        ok-text="Yes"
        cancel-text="No"
        @confirm="onReloadConfirm"
        @cancel="onReloadCancel"
        :disabled="module.module_status=='RUNNING'"
      >
        <template #title>
          <p>Reload {{ module.name }}?</p>
        </template>
        <a-button v-if="loggedInEsos" :disabled="module.module_status=='RUNNING'">
          <StopOutlined />Reload
        </a-button>
      </a-popconfirm>
    </td>
  </tr>
</template>

<script>
import { CheckCircleOutlined, WarningOutlined, DeleteOutlined, EditOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons-vue';
import { authComputed } from '../vuex/helpers.js'
import ModuleServices from '@/services/ModuleServices.js'

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  components: {
    CheckCircleOutlined, WarningOutlined, DeleteOutlined, EditOutlined, PlayCircleOutlined, StopOutlined
  },
  computed: {
    ...authComputed
  },
  methods: {
    onConfirm() {
      console.log("Delete " + this.module.name);
      ModuleServices.deleteModule(this.module.name).then(response => {
        console.log(response.data.status.value);
        if (response.data.status.value == 0) {
          console.log("Delete OK");
          this.openNotificationWithIcon("success", 'Delete Module successful', 'The module ' + this.module.name + ' was sucessfully deleted.');
          this.$emit('update-module-list');
        } else {
          this.openNotificationWithIcon("error", 'Error deleting module', response.data.status.value + " - " + response.data.status.text);
        }
      }, response => {
        console.log("Delete Error " + response);
        this.openNotificationWithIcon("error", 'Error deleting module', String(response));
      });
    },
    onCancel() {
      console.log("Delete not");
    },
    openNotificationWithIcon(type, message, description) {
      this.$notification[type]({
        message: message,
        description: description,
      });
    },
    onStartConfirm() {
      console.log("Start Module " + module.name);
      ModuleServices.startModule(this.module.name).then(response => {
        console.log(response.data.status.value);
        if (response.data.status.value == 0) {
          console.log("Start OK");
          this.openNotificationWithIcon("success", 'Start Module successful', 'The module ' + this.module.name + ' was sucessfully started.');
          this.$emit('update-module-list');
        } else {
          this.openNotificationWithIcon("error", 'Error starting module', response.data.status.value + " - " + response.data.status.text);
        }
      }, response => {
        console.log("Start Error " + response);
        this.openNotificationWithIcon("error", 'Error starting module', String(response));
      });
    },
    onStartCancel() {
      console.log("Do not start Module");
    }, 
    onStopConfirm() {
      console.log("Stop Module");
      ModuleServices.stopModule(this.module.name).then(response => {
        console.log(response.data.status.value);
        if (response.data.status.value == 0) {
          console.log("Stop OK");
          this.openNotificationWithIcon("success", 'Stop Module successful', 'The module ' + this.module.name + ' was sucessfully stopped.');
          this.$emit('update-module-list');
        } else {
          this.openNotificationWithIcon("error", 'Error stopping module', response.data.status.value + " - " + response.data.status.text);
        }
      }, response => {
        console.log("Stop Error " + response);
        this.openNotificationWithIcon("error", 'Error stopping module', String(response));
      });
    }, 
    onStopCancel() {
      console.log("Do not stop Module");
    }, 
    onReloadConfirm() {
      console.log("Reload Module");
      ModuleServices.reloadModule(this.module.name).then(response => {
        console.log(response.data.status.value);
        if (response.data.status.value == 0) {
          console.log("Reload OK");
          this.openNotificationWithIcon("success", 'Reload Module successful', 'The module ' + this.module.name + ' was sucessfully reloaded.');
          this.$emit('update-module-list');
        } else {
          this.openNotificationWithIcon("error", 'Error reloading module', response.data.status.value + " - " + response.data.status.text);
        }
      }, response => {
        console.log("Reload Error " + response);
        this.openNotificationWithIcon("error", 'Error reloading module', String(response));
      });
    }, 
    onReloadCancel() {
      console.log("Do not reload Module");
    }, 
    onEdit() {
      ModuleServices.getModuleConfig(this.module.name)
        .then(response => {
          this.$store.module_config = response.data.config;
          this.$store.module_config_name = this.module.name;
          this.$router.push({ name: 'ConfigurationModuleDetails' });
      }, response => {
        console.log("Delete Error " + response);
        this.openNotificationWithIcon("error", 'Error Editing Module', String(response));
      });

    },
    getReducedModuleName(moduleName) {
      return moduleName.replace("EsosModule" + this.group, "")
    }
  }
}
</script>

<style scoped>
.configuration-module-card {
  padding: 10px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 2px;
}

.configuration-module-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
</style>