<template>
  <div class="variables-module-card" >
    <div style="display:flex;justify-content: space-between;" >
      <div style="display: flex">
        <a-badge :count="getModuleCommErrorCount(module)">
          <a-tooltip :title="getModuleCommErrorTitle(module)">
            <div v-if="module.module_status==='RUNNING'" >
              <CheckCircleOutlined :style="{fontSize: '25px', color: '#090'}"/>
            </div>
            <div v-else>
              <WarningOutlined  :style="{fontSize: '25px', color: '#f00'}"/>
            </div>
          </a-tooltip>
        </a-badge>
        <h3>{{ getReducedModuleName(module.name) }}</h3>
      </div>
      <div v-if="loggedInAdmin" style="text-align:left">
        <play-circle-outlined @click=onOpenDetails  :style="{fontSize: '25px', color: 'blue'}"></play-circle-outlined>
        <Select v-model:value="loglevel" @change="onDebugLevelChange" :showArrow=false  :dropdownMatchSelectWidth=80>
          <a-select-option value="20">Info</a-select-option>
          <a-select-option value="10">Debug</a-select-option>
          <a-select-option value="0">Trace</a-select-option>
        </Select>
        <!--
        Level:
        <RadioGroup v-model:value="loglevel" size="small" @change=onDebugLevelChange>
          <RadioButton value="20">Info</RadioButton>
          <RadioButton value="10">Debug</RadioButton>
          <RadioButton value="0">Trace</RadioButton>
        </RadioGroup>
        -->

        <a-switch checked-children="var" un-checked-children="var" v-model:checked="showVariables" @change="onShowVariablesChanged"/>
        <Select v-model:value="ui_loglevel" @change="onUiLevelChange" :showArrow=false :dropdownMatchSelectWidth=80>
          <a-select-option value="20">Info</a-select-option>
          <a-select-option value="10">Debug</a-select-option>
          <a-select-option value="0">Trace</a-select-option>
        </Select>
        <!--
        <RadioGroup v-model:value="ui_loglevel" size="small" @change=onUiLevelChange>
          <RadioButton value="20">Info</RadioButton>
          <RadioButton value="10">Debug</RadioButton>
          <RadioButton value="0">Trace</RadioButton>
        </RadioGroup>
        -->
      </div>
    </div>
    <a-collapse>
      <a-collapse-panel v-for="(group, index) in module.sub_module_list" :key="index" :header="index">
        <VariablesModuleCard v-for="module in group" :key="module.name" :module="module" @update-module-list="onUpdateModuleList" />
      </a-collapse-panel>
    </a-collapse>

  </div>
  <a-modal v-model:visible="showActions" width="1200px">
    <variables-module-details></variables-module-details>
  </a-modal>
</template>

<script>

import store from '../vuex/store.js'
import { CheckCircleOutlined, WarningOutlined, PlayCircleOutlined } from '@ant-design/icons-vue';
import { Select } from 'ant-design-vue';
import ModuleServices from '@/services/ModuleServices.js'
import { authComputed } from '../vuex/helpers.js'
import VariablesModuleDetails from '../views/VariablesModuleDetails';

export default {
  emits: ["updateModuleList"], 
  data() {
    return {
      loglevel: "20",
      ui_loglevel: "20",
      showVariables: false,
      showActions: false
    }
  },
  props: {
    module: {
      type: Object,
      required: true,
    }, 
    group: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.loglevel = '' + this.module.loglevel;
    var result = new Object();
    result.name = this.module.name;
    // query, if variables of current module are viewed currently
    store.commit("IS_SELECTED_MODULE", result);
    this.showVariables = result.value;
    result.name = this.module.name;
    store.commit("SELECTED_MODULE_GET_LEVEL", result);
    this.ui_loglevel = result.value;
    this.timeoutLoop = setTimeout(this.onUpdateShowVariables, 1000);
  },
  unmounted() {
    clearTimeout(this.timeoutLoop);
  },
  components: {
    CheckCircleOutlined, WarningOutlined, PlayCircleOutlined, Select,  VariablesModuleDetails
  },
  computed: {
    ...authComputed
  },
  methods: {
    onOpenDetails() {
      this.$store.modules_variables_name = this.module.name;
      this.$store.module_variables = {}
      //this.$router.push("/variables_module_details")
      this.showActions = true

    },
    onDebugLevelChange() {
      //this.module.loglevel = this.loglevel;
      console.log("Set " + this.module.name + " to Log-Level: " + this.loglevel);
      ModuleServices.sendModuleDebugging(this.module.name, this.loglevel);
    },
    onUpdateModuleList() {
      console.log("Update Module List");
    },
    onShowVariablesChanged() {
      if (this.showVariables) {
        store.commit("ADD_SELECTED_MODULE", this.module.name);
      } else {
        store.commit("REMOVE_SELECTED_MODULE", this.module.name);
      }
    },
    onUiLevelChange() {
      //will be called, when ui-level of module changed
      store.commit("SELECTED_MODULE_SET_LEVEL", [this.module.name, this.ui_loglevel])
    },
    onUpdateShowVariables() {
      var result = new Object();
      result.name = this.module.name;
      store.commit("IS_SELECTED_MODULE", result);
      this.showVariables = result.value;
      this.timeoutLoop = setTimeout(this.onUpdateShowVariables, 1000);
    },
    getReducedModuleName(moduleName) {
      // remove leading "EsodModule"
      var result = moduleName.replace("EsosModule", "");
      // remove name of group
      var result2 = result.replace(this.group, "");
      if (!result2.startsWith("_"))
        // do not remove name of group, if text will become empty
        result = result2;
      // remove name of parent module (Batsys_1_Inverter_1 -> Inverter_1)
      var list = result.split("_")
      while (list.length > 2) {
        list.shift();
      }
      var result3 = "";
      for (var [, s] of Object.entries(list)) {
        if (result3)
          result3 += "_";
        result3 += s;
      }
      return result3;
    },
    getModuleCommErrorCount(module) {
      let count = 0;
      for (const key in module.comm_status) {
        if (module.comm_status[key].value != 0) {
          count++;
        }
      }
      return count;
    },
    getModuleCommErrorTitle(module) {
      let title = module.module_status;
      for (const key in module.comm_status) {
        //if (module.comm_status[key].value != 0) {
          title += "\n";
          title = title + key + ": (" + module.comm_status[key].value + ") " + module.comm_status[key].text;
        //}
      }
      return title;

    }

  }
}
</script>

<style scoped>
.variables-module-card {
  padding: 5px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 2px;
}
</style>

<style>
.ant-collapse-content .ant-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 6px;
  padding-left: 26px;
}
.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding-top: 6px;
  padding-bottom: 6px;

}

.ant-tooltip-inner {
  white-space: pre;
}

.ant-tooltip-placement-top {
  max-height: 1000px;
  max-width: 1000px;
}
</style>