<template>
  
  <div class="home" v-if="plant_info">
    <div v-if="plant_info.Plant">
      <plant-location :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-location>
      <plant-location-status v-if="!loggedIn" :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-location-status>
      <plant-location-status-control v-if="loggedIn" :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-location-status-control>
      <plant-modi :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-modi>
      <plant-frequency :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-frequency>
      <plant-meter-power :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-meter-power>
      <plant-meter-energy :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-meter-energy>
      <plant-inverter :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-inverter>
      <plant-battery :width="box_width" :height="box_height" :p="plant_info" :t="locale" ></plant-battery>
    </div>
    <div v-if="plant_info.calendar_info">
      <div v-if="loggedIn">
        <notification-calendar :list-data="plant_info.calendar_info.calendar" :user-data="plant_info.calendar_info.user"></notification-calendar>
      </div>
    </div>
    <div v-if="plant_info.version_info">
      <version-info :version_info="plant_info.version_info"></version-info>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { authComputed } from '../vuex/helpers.js'
import PlantLocation from '../components/PlantInfos/PlantLocation.vue'
import PlantLocationStatus from '../components/PlantInfos/PlantLocationStatus.vue'
import PlantLocationStatusControl from '../components/PlantInfos/PlantLocationStatusControl.vue'
import PlantModi from '../components/PlantInfos/PlantModi.vue'
import PlantFrequency from '../components/PlantInfos/PlantFrequency.vue'
import PlantMeterPower from '../components/PlantInfos/PlantMeterPower.vue'
import PlantMeterEnergy from '../components/PlantInfos/PlantMeterEnergy.vue'
import PlantBattery from '../components/PlantInfos/PlantBattery.vue'
import PlantInverter from '../components/PlantInfos/PlantInverter.vue'
import ModuleServices from '@/services/ModuleServices.js'
import VersionInfo from '../components/VersionInfo.vue'
import NotificationCalendar from './NotificationCalendar.vue'

export default {
  name: 'Home',
  components: {
    PlantLocation,
    PlantLocationStatus,
    PlantLocationStatusControl,
    PlantModi,
    PlantFrequency,
    PlantMeterPower,
    PlantMeterEnergy,
    PlantBattery,
    PlantInverter,
    VersionInfo,
    NotificationCalendar
},
  data() {
    return {
      box_width: 212,
      box_height: 227,
      timeoutLoop: null,
      locale_en: {
        "Plant": "Anlage",
        "Location": "Standort",
        "PlantIdentifier": "Anlagen ID",
        "PlantStatus": "Anlagenstatus",
        "PlantStatusActive": "Aktiv",
        "PlantStatusInactive": "Inaktiv",
        "Usecases": "Anwendungsfälle",
        "TradedPower": "Vermarktete Leistung",
        "VneId": "VNE/ID",
        "Fcr": "PRL",
        "Frequency": "Frequenz",
        "GridFrequency": "Netzfrequenz",
        "ReactivePower": "Blindleistung",
        "ActivePower": "Wirkleistung",
        "MeterPower": "Zähler Leistungen",
        "MeterEnergy": "Zähler Energieen",
        "Charged": "Geladen",
        "Discharged": "Entladen",
        "Battery": "Batterie",
        "Cycles": "Zyklen",
        "TotalCapacity": "Gesamtkapazität",
        "StateOfCharge": "Ladezustand",
        "Inverter": "Wechselrichter",
      },
      locale: {
        "Plant": "Plant",
        "Location": "Location",
        "PlantIdentifier": "Plant Identifier",
        "PlantStatus": "Plant Status",
        "PlantStatusActive": "Active",
        "PlantStatusInactive": "Inactive",
        "PlantOnOff": "Plant Control",
        "PlantControlStart": "Start Plant",
        "PlantControlStop": "Stop Plant",
        "PlantControlStartQuestion": "Start Plant?",
        "PlantControlStopQuestion": "Stop Plant?",
        "Usecases": "Usecases",
        "TradedPower": "Traded Power",
        "VneId": "VNE/ID",
        "Fcr": "FCR",
        "Frequency": "Frequency",
        "GridFrequency": "Grid Frequency",
        "ReactivePower": "Reactive Power",
        "ActivePower": "Active Power",
        "MeterPower": "Meter Power",
        "MeterEnergy": "Meter Energy",
        "Charged": "Charged",
        "Discharged": "Discharged",
        "Battery": "Battery",
        "Cycles": "Cycles",
        "TotalCapacity": "Total Capacity",
        "StateOfCharge": "State of Charge",
        "Inverter": "Inverter",
      },
      plant_info: null
    }
  },
  mounted() {
    console.log("Mounted")
    this.onReloadData();
  },
  unmounted() {
      console.log("UnMounted")
      console.log("Stop loop");
      clearTimeout(this.timeoutLoop);
      this.timeoutLoop = null;
  },
  computed: {
    ...authComputed
  },
  methods: {
    onReloadData() {
      ModuleServices.getPlantStatusInfo()
        .then(response => {
          this.plant_info = response.data.plant_info;
        })
        .catch(error => {
          this.plant_info = null;
          if ((error.response != null) && (error.response.status == 422)) {
            this.$router.push("/login");
          }
        })
      this.timeoutLoop = setTimeout(this.onReloadData, 1000);
    }
  }
}
</script>

<style>
.svg_with_border {
  box-shadow: 0px 0px 0px 0px silver;
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

</style>