<template>
    <div class=module-action>
        <h1>{{ actionName }}</h1>
        <div :class="getActionName()" :id="getActionName()"></div>
        <a-popconfirm
            title="Are you sure execute this action?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="onSendAction"
        >
            <a-button v-if="action_confirm" type="primary">Send Action</a-button>
        </a-popconfirm>
        <a-button v-if="!action_confirm" @click=onSendAction>Send Action</a-button>

    </div>
</template>


<script>

import { createApp } from "vue"
//import router from '../router/index.js'
import ModuleServices from '@/services/ModuleServices.js'
import EditParamFloat from '../components/EditParamFloat.vue';
import EditParamInt from '../components/EditParamInt.vue';
import EditParamBool from '../components/EditParamBool.vue';
import EditParamStr from '../components/EditParamStr.vue';
import EditParamBitmask from '../components/EditParamBitmask.vue';

export default {
    name: 'ModuleAction',
    data() {
        return {
            actionName: null,
        };
    },
    props: {
        action_name: {
            type: String,
            required: true
        },
        action_params: {
            type: Object,
            required: true
        },
        action_confirm: {
            type: Boolean,
            required: true
        },
        module_name:  {
            type: String,
            required: true
        },
        action_non_blocking: {
            type: Boolean,
            required: true
        }
    },
    mounted() {
        this.actionName = this.action_name;
        var params_container = null;
        console.log("ModuleAction: " + this.actionName);
        setTimeout(() => {
            params_container = document.querySelector('.' + this.getActionName());
            if (params_container == null)
                return
            params_container.innerHTML = '';
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.action_params)) {
                var comp = null;
                if (value.param_type.editor_type === "float")
                    comp = createApp(EditParamFloat, { "param": value });
                else if (value.param_type.editor_type === "int")
                    comp = createApp(EditParamInt, { "param": value });
                else if (value.param_type.editor_type === "bool")
                    comp = createApp(EditParamBool, { "param": value });
                else if (value.param_type.editor_type == "string")
                    comp = createApp(EditParamStr, { "param": value });
                else if (value.param_type.editor_type == "bitmask")
                    comp = createApp(EditParamBitmask, {"param": value});
                
                if (comp != null) {
                    const wrapper = document.createElement("div");
                    comp.use(this.$router);
                    comp.use(this.$store)
                    comp.mount(wrapper);
                    params_container.appendChild(wrapper);
                }
            }
        }, 1);
    },
    methods: {
        openNotificationWithIcon(type, message, description, duration=5) {
            const key = this.module_name;
            this.$notification[type]({
                key,
                message: message,
                description: description,
                duration: duration
            });
        },
        onSendAction() {
            console.log("Send Action");
            var that = this;
            this.openNotificationWithIcon("info", 'Sending action...', 'The action ' + this.actionName + ' will be sent to ' + this.module_name + '.');
            var timeout = 3000;
            if (this.action_non_blocking == true) {
                console.log("Wait 60s for answer because of non_blocking action.")
                timeout = 60000;
            }
            ModuleServices.sendModuleAction(this.module_name, this.actionName, this.action_params, timeout)
                .then(function(response) {
                    if (response.data.status.value == 0) {
                        that.openNotificationWithIcon("success", 'Sending action successfull', 'The action ' + that.actionName + ' was sucessfully sent to ' + that.module_name + '.');
                    } else {
                       that.openNotificationWithIcon("error", 'Error sending action', 'The action ' + that.actionName + ', sent to ' + that.module_name + ', returned an error (' + response.data.status.value + '):' + response.data.status.text, 0);
                    }
                })
                .catch(function(error) {
                    that.openNotificationWithIcon("error", 'Error sending action', 'The action ' + that.actionName + ', could not be sent to ' + that.module_name + '!\r\n' + error, 0);
                });
        }
        ,
        getActionName() {
            if (this.actionName != null) {
                return this.module_name + "_" + this.actionName.replace(/\s+/g, '_');
            } else {
                return this.module_name + "_" + "---";
            }
        }
    }


}
</script>

<style scoped>
.module-action {
  padding: 4px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid #39495c;
  margin-bottom: 6px;
}

</style>