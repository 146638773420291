<template>
<div style="min-width:1200px">
  <a-row  type="flex" justify="space-around" align="top">
    <a-col>
      Items per page:
      <a-select
        v-model:value="itemsPerPage"
        style="width: 70px"
        @change="handleChangePageSize"
      >
        <a-select-option value=5>5</a-select-option>
        <a-select-option value=10>10</a-select-option>
        <a-select-option value=25>25</a-select-option>
        <a-select-option value=50>50</a-select-option>
        <a-select-option value=100>100</a-select-option>
      </a-select>
    </a-col>
    <a-col>
      <a-button @click="onReloadClick">Reload</a-button>
    </a-col>
  </a-row>
  <div class="notiList" ref="notiList" style="height:100%">
  <a-table 
    class="ant-table-striped"
    :dataSource="logging_list" 
    :row-key="record => record._id"
    :loading="loading"
    :columns="columns" 
    :pagination="pagination"
    :transformCellText="({text, column, record, index}) => onTransformText(text, column, record, index)"
    @change="handleTableChange"
    :expandIcon="(props) => getExpandIcon(props)"
    size="small">
  </a-table>
  {{selectedRowKeys}}
</div>

  <a-modal v-model:visible="visibleConfirm" title="Confirm Logging" @ok="handleConfirmOK">
    <a-form :model="confirmState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Comment">
        <a-input v-model:value="confirmState.comment" />
      </a-form-item>
      <a-form-item label="Editor Name">
        <a-input v-model:value="confirmState.editor" />
      </a-form-item>
    </a-form>
  </a-modal>
</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ModuleServices from "@/services/ModuleServices.js";
import { PlusCircleOutlined, MinusCircleOutlined, UserOutlined, DownOutlined } from '@ant-design/icons-vue';

export default {
  name: "LoggingList",
  data() {
    return {
      logging_list: [],
      logging_filter_criteria: {},
      scrolly: '100%',
      height: 0,
      pagination: {
        total: 1,
        current: 1,
        pageSize: 25,
        position: 'bottom'
      },
      loading: false,
      itemsPerPage: 25,
      showSubCodes: false,
      tableFilters: {},
      columns: [
          { 
            title: 'Core',
            dataIndex: 'core_id',
            key: 'core_id',
            ellipsis: true,
            width: '70px'
          },
          { 
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '170px',
            sortDirections: ['descend'],
            sorter: (a, b) => a.time - b.time,
          },
          { 
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            width: '6%',
          },
          { 
            title: 'Type',
            dataIndex: 'info_type',
            key: 'info_type',
            width: '6%',
            filters: [
            {
                text: 'Config',
                value: 'config',
            },
            {
                text: 'Action',
                value: 'action',
            },
            {
                text: 'Login',
                value: 'login',
            }]
          },
          { 
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '5%',
          },
          { 
            title: 'Module',
            dataIndex: 'module_name',
            key: 'module_name',
            width: '12%',

          },
          { 
            title: 'Subtype',
            dataIndex: 'info_subtype',
            key: 'info_subtype',
            width: '6%'
          },
          { 
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            slots: {
              customRender: 'data'
            }
          }
      ],
      selectedRowKeys: [],
      hasSelected: false,
      rowSelection: {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys /*, selectedRows*/) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`);
          this.selectedRowKeys = selectedRowKeys;
          this.rowSelection.selectedRowKeys = selectedRowKeys;
          this.hasSelected = selectedRowKeys.length > 0;
        },
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 34,
      },
      visibleConfirm: false,
      confirmState: {
        comment: "",
        editor: ""
      }

    };
  },
  components: {
    PlusCircleOutlined, MinusCircleOutlined, UserOutlined, DownOutlined
  },
  created() {
    this.onLoadLoggingList();
    // create a listener, which will be called after window has been resized
    window.addEventListener("resize", this.handlerResize);
  },
  mounted() {
    //this.timeoutLoop = setTimeout(this.onReadVariables, 1000);
    this.handlerResize();
    /*
    ModuleServices.getLoggingFilterCriteria()
      .then((response) => {
        this.logging_filter_criteria = response.data.data;
        for (const col of this.columns) {
          if (col.key in this.logging_filter_criteria) {
            col.filters = []
            for (const index in this.logging_filter_criteria[col.key]) {
              const item = this.logging_filter_criteria[col.key][index];
              if (item != null) {
                col.filters.push({
                  "text":item, "value":item
                });
              }
            }
          }
        }
      })
      .catch((error) => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
      });
      */
  },
  unmounted() {
    window.removeEventListener("resize", this.handlerResize);
  },
  methods: {
    onLoadLoggingList() {      
      var filter = {};
      if (this.showCoreId && this.tableFilters.core_id && (this.tableFilters.core_id.length > 0))
        filter.core_id = {"$in": this.tableFilters.core_id};
      if (this.tableFilters.info_type && (this.tableFilters.info_type.length > 0))
        filter.info_type = {"$in": this.tableFilters.info_type};
      var data = { "filter": filter, "pagination": this.pagination }

      var that = this;

      ModuleServices.putLoggingList(data)
        .then((response) => {
          that.logging_list = response.data.data;
          that.pagination.total = response.data.count;
          var new_selectedRowKeys = []
          for (const logi of that.logging_list) {
            if (that.selectedRowKeys.includes(logi._id)) {
              new_selectedRowKeys.push(logi._id);
            }
          }
          that.selectedRowKeys = new_selectedRowKeys;
          that.rowSelection.selectedRowKeys = that.selectedRowKeys;
        })
        .catch((error) => {
          if ((error.response != null) && (error.response.status == 422)) {
            this.$router.push("/login");
          } else {
            console.log(error);
          }
        });
    },
    handleTableChange(pag, filters, /*sorter*/) {
        this.tableFilters = filters
        this.pagination.current = pag.current;
        this.onLoadLoggingList();
    },
    handleChangePageSize(value) {
        this.pagination.pageSize = parseInt(value)
        this.pagination.current = 1
        this.onLoadLoggingList()
    },
    handlerResize() {
        // handler, after window has been resized
        // set this.scrolly to enable scrollin inside table to get fixed header and to make table as high as window
        this.height = this.$refs.notiList.clientHeight
        this.scrolly = this.height - 120
    },
    /* eslint-disable no-unused-vars */
    onTransformText(text, column, record, index) {
        if (column.key == 'time') {
            return new Date(record.time*1000).toLocaleString()
        } else if (column.key == 'data') {
          return this.onTransformTextData(record);
        } else  {
          return text;
        }
    },
    getTopicString(key) {
      // topic string is given in format " root['topics']['frequency_out']['value']	"
      // convert this to format " /topics/frequency_out "
      // remove root at the beginning and remove ['value'] at the end.
      if (key.endsWith("['value']"))
        key = key.substring(0, key.length - 9);
      let result = "";
      let regex = /(?<=\[')(.*?)(?='\])/gm;
      for (const s of key.match(regex)) {
        result += "/" + s;
      }
      return result;
    },
    onTransformTextData(record) {
      if (record.info_type == 'config') {
        if (record.info_subtype == 'change') {
          var diff = "";
          if (("config_diff" in record.data) && ("type_changes" in record.data.config_diff)) {
            for (const [key, value] of Object.entries(record.data.config_diff.type_changes)) {
              if (diff != "")
                diff += "<br/>";
              diff += this.getTopicString(key);
              diff += "<t class='old_value'>" + value.old_value + "</t>";
              diff += "->";
              diff += "<t class='new_value'>" + value.new_value + "</t>";
            }
          }
          if (("config_diff" in record.data) && ("values_changed" in record.data.config_diff)) {
            for (const [key, value] of Object.entries(record.data.config_diff.values_changed)) {
              if (diff != "")
                diff += "<br/>";
              diff += this.getTopicString(key);
              diff += "<t class='old_value'>" + value.old_value + "</t>";
              diff += "->";
              diff += "<t class='new_value'>" + value.new_value + "</t>";
            }
          }
          if (diff == "") {
            diff = "No Changes!";
          }
          return <div v-html={diff}></div>;
        } else if (record.info_subtype == 'start') {
          return "Start Module";
        } else if (record.info_subtype == 'stop') {
          return "Stop Module";
        } else if (record.info_subtype == 'new') {
          return "New Module";
        } else if (record.info_subtype == 'delete') {
          return "Delete Module";
        } else if (record.info_subtype == 'reload') {
          return "Reload Module";
        }
      } else if (record.info_type == 'login') {
        if (record.status == 0) {
          return "Login";
        } else {
          return "Login failed!"
        }
      } else if (record.info_type == 'action') {
        var param = "";
        if ("action_param" in record.data) {
          for (const [key, value] of Object.entries(record.data.action_param)) {
            if (key == "status")
              continue;   // ignore param status, because this has no valid data in actions
            if (param != "")
              param += "<br/>";
            param += key;
            param += " = ";
            param += value.value;
          }
        }
        if (param == "") {
          param = "No Parameters!";
        }
        return <div v-html={param}></div>;
      }
    },
    onFilterGroup(/*value, record*/) {
      //console.log("Filter " + value + " - " + record);
      return true;
    },
    onReloadClick() {
      this.onLoadLoggingList();
    },
    handleMenuClick(event) {
      console.log(event);
      if (event.key == 1) {
        this.confirmState.comment = "";
        this.visibleConfirm = true;
      }
    },
    handleConfirmOK(event) {
      console.log(event);
      this.visibleConfirm = false;
      var request = {
        comment: this.confirmState.comment,
        editor: this.confirmState.editor,
        selectedMessages: this.selectedRowKeys
      }
      console.log(request);
      ModuleServices.putLoggingConfirm(request);
      this.onReloadClick();
    },
    getExpandIcon(props) {
      if (props.record.comments) {
        if (props.expanded) {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><MinusCircleOutlined /></a>
        } else {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><PlusCircleOutlined /></a>
        }
      } else 
        return null
    }


  },
};
</script>

<style scoped>
.ant-table-striped :deep(.table-pending) td {
  background-color: #ffb1b1a2;
}

.ant-table.ant-table-small  {
  padding: 4px 4px;
  white-space:pre-wrap;
}
.ant-table.ant-table-small  .ant-table-content  .ant-table-body  table  .ant-table-tbody  tr  td  {
  padding: 4px 4px !important;
  white-space:pre-wrap !important;
}

.td {
  white-space:pre-wrap;
}

</style>


<style>
.old_value {
  background: #ffa3a3a2 !important;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.new_value {
  background: #a0ffb0a2;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>