<template>
      <td style="width:30%">
        <span style="font-weight:bold">{{ config.name }}</span> (Float) {{getDescription(config)}}
      </td>
      <td>
        <Input v-model:value="unit_value" @change="onInputChanged" style="width:150px;text-align:right"></Input>
        <span style="font-weight:bold;margin-left:10px">{{ unit_prefix }}{{config_temp.unit}}</span>  <span style="font-size:10px">({{config_temp.value}})</span>

      </td>
</template>

<script>
import { Input } from 'ant-design-vue';
import Utils from '../services/Utils';

export default {
  data() {
    return {
      config_temp: {},
      unit_factor: 1,
      unit_prefix: "",
      unit_value: ""
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  components: { 
    Input,
  },
  created() {
    this.config_temp = this.config;
    this.unit_prefix = "";
    this.unit_factor = 1;
    var result = Utils.getFormattedFloatString(this.config_temp.value, this.config_temp.unit)
    this.unit_value = result.value;
    this.unit_prefix = result.unit_prefix;
    this.unit_factor = result.unit_factor;
  },  methods:
  {
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    },
    onInputChanged: function(e) {
      var result = Utils.setFormattedFloatString(e.target.value, this.config_temp.unit, this.unit_prefix, this.unit_factor);
      this.unit_prefix = result.unit_prefix;
      this.unit_factor = result.unit_factor;
      this.unit_value = result.unit_value;
      this.config_temp.value = result.value;
      
    },
  }
}
</script>

<style scoped>
tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px;
  text-align: left;
}
</style>