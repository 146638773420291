<template>
      <td style="width:30%">
          <span style="font-weight:bold">{{ config.name }}</span> (Password) {{getDescription(config)}}
      </td>
      <td>
          <Input v-model:value="config_temp.value" type="password"></Input>
      </td>
</template>

<script>
import { Input } from 'ant-design-vue';

export default {
  data() {
    return {
      config_temp: {},
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  components: { 
    Input,
  },
  created() {
    this.config_temp = this.config;
  },
  methods:
  {
    getDescription(config) {
      if (config.description) {
        return " ( " + config.description + " )";
      } else {
        return ""
      }
    }
  }
}
</script>

<style scoped>
tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
}

th,
td {
  padding: 6px;
  text-align: left;
}
</style>