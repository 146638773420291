<template>
<div style="min-width:1200px">
  <a-row  type="flex" justify="space-around" align="top">
    <a-col>
      <a-dropdown :disabled="!hasSelected">
      <template #overlay>
        <a-menu @click="handleMenuClick">
          <a-menu-item key="1">
            <UserOutlined />
            Confirm
          </a-menu-item>
        </a-menu>
      </template>
      <a-button>
        Menu
        <DownOutlined />
      </a-button>
    </a-dropdown>
    </a-col>
    <a-col>
      Show Project
      <a-switch 
        v-model:checked="showProject"
        @click="onShowProject" />
    </a-col>
    <a-col>
      Show Core-ID
      <a-switch 
        v-model:checked="showCoreId"
        @click="onShowCoreId" />
    </a-col>
    <a-col>
      Only pending
      <a-switch 
        v-model:checked="pendingNotifications"
        @click="onPendingNotificationsClick" >
      </a-switch>
    </a-col>
    <a-col>
      Show Sub-Codes
      <a-switch 
        v-model:checked="showSubCodes"
        @click="onShowSubCodes(true)" />
    </a-col>
    <a-col>
      Full message
      <a-switch 
        v-model:checked="showFullMessage"
        @click="onShowFullMessage"/>
    </a-col>
      <!--   funktioniert noch nicht!!!
      <a-switch 
        checked-children="Group" 
        un-checked-children="List" 
        v-model:checked="groupNotifications"
        @click="onGroupNotificationsClick" />
      -->
    <a-col>
      Items per page:
      <a-select
        v-model:value="itemsPerPage"
        style="width: 70px"
        @change="handleChangePageSize"
      >
        <a-select-option value=25>25</a-select-option>
        <a-select-option value=50>50</a-select-option>
        <a-select-option value=100>100</a-select-option>
        <a-select-option value=200>200</a-select-option>
      </a-select>
    </a-col>
    <a-col>
      <a-button @click="onReloadClick">Reload</a-button>
    </a-col>
  </a-row>
  <div class="notiList" ref="notiList" style="height:100%">
  <a-table 
    class="ant-table-striped"
    :row-selection="rowSelection"
    :dataSource="notification_list" 
    :row-key="record => record._id"
    :loading="loading"
    :columns="columns" 
    :pagination="pagination"
    :rowClassName="(record, index) => (!record.time_end ? 'table-pending' : null)"
    :transformCellText="({text, column, record, index}) => onTransformText(text, column, record, index)"
    @change="handleTableChange"
    :expandIcon="(props) => getExpandIcon(props)"
    size="small">
<!--
    expandRowByClick="true"
    :scroll="{ y: scrolly }"
-->
    
    
    <template #expandedRowRender="{ record }">
      <p style="margin: 0" >
        <a-table 
          :dataSource="record.comments" 
          :columns="commentsColumns"
          :transformCellText="({text, column, record, index}) => onTransformText(text, column, record, index)"
          :pagination=false
          size="small"/>
      </p>
    </template>
  </a-table>
</div>

  <a-modal v-model:visible="visibleConfirm" title="Confirm Notification" @ok="handleConfirmOK">
    <a-form :model="confirmState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Comment">
        <a-input v-model:value="confirmState.comment" />
      </a-form-item>
      <a-form-item label="Editor Name">
        <a-input v-model:value="confirmState.editor" />
      </a-form-item>
      <a-form-item label="Confirm">
        <a-checkbox v-model:checked="confirmState.confirm">Set time_end to current date/time</a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ModuleServices from "@/services/ModuleServices.js";
import { PlusCircleOutlined, MinusCircleOutlined, UserOutlined, DownOutlined } from '@ant-design/icons-vue';
import store from '../vuex/store.js'

export default {
  name: "NotificationList",
  data() {
    return {
      notification_list: [],
      notification_filter_criteria: {},
      scrolly: '100%',
      height: 0,
      pagination: {
        total: 1,
        current: 1,
        pageSize: 25,
        position: 'bottom'
      },
      loading: false,
      itemsPerPage: 25,
      showSubCodes: false,
      showCoreId: true,
      showProject: false,
      showFullMessage: false,
      groupNotifications: false,
      pendingNotifications: false,
      tableFilters: {},
      columns: [
          { 
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            ellipsis: true,
            width: '0'
          },
          { 
            title: 'Core',
            dataIndex: 'core_id',
            key: 'core_id',
            ellipsis: true,
            width: '0'
          },
          { 
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '170px',
            sortDirections: ['descend'],
            sorter: (a, b) => a.time - b.time,
          },
          { 
            title: 'Time (end)',
            dataIndex: 'time_end',
            key: 'time_end',
            width: '170px',
            sortDirections: ['descend'],
            sorter: (a, b) =>  { 
              if (! a.time_end) return 1;
              if (! b.time_end) return -1;
              return a.time_end - b.time_end 
            },
          },       
          { 
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            width: '6%',
          },
          { 
            title: 'Spec-Nr.',
            dataIndex: 'spec_number',
            key: 'spec_number',
            width: '12%',
            ellipsis: true
          },
          { 
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
          },
          { 
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            width: '5%',
            filters: [
            {
                text: 'I',
                value: 'I',
            },
            {
                text: 'W',
                value: 'W',
            },
            {
                text: 'E',
                value: 'E',
            },
            {
                text: 'SL1',
                value: 'SL1',
            },
            {
                text: 'SL2',
                value: 'SL2',
            },
            {
                text: 'SL3',
                value: 'SL3',
            }]

          },
          { 
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: '5%'
          },
          { 
            title: 'Sub-Code',
            dataIndex: 'sub_code',
            key: 'sub_code',
            ellipsis: true,
            width: '0',
          },
          { 
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: '5%'
          },
          { 
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            ellipsis: !this.showFullMessage,
          }
      ],
      commentsColumns: [
          { 
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            width: '170px',
            sortDirections: ['descend'],
            sorter: (a, b) => a.time - b.time,
          },
          { 
            title: 'Editor',
            dataIndex: 'editor',
            key: 'editor',
            width: '250px',
          },
          { 
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment'
          }
      ],
      selectedRowKeys: [],
      hasSelected: false,
      rowSelection: {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys /*, selectedRows*/) => {
          this.selectedRowKeys = selectedRowKeys;
          this.rowSelection.selectedRowKeys = selectedRowKeys;
          this.hasSelected = selectedRowKeys.length > 0;
        },
        getCheckboxProps: record => ({
          // Column configuration not to be checked
          name: record._id.toString(),
        }),
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 34,
      },
      visibleConfirm: false,
      confirmState: {
        comment: "",
        editor: "",
        confirm: false
      }

    };
  },
  components: {
    PlusCircleOutlined, MinusCircleOutlined, UserOutlined, DownOutlined
  },
  created() {
    // create a listener, which will be called after window has been resized
    window.addEventListener("resize", this.handlerResize);
  },
  mounted() {
    //this.timeoutLoop = setTimeout(this.onReadVariables, 1000);
    this.handlerResize();
    this.showProject = store.state.notification_settings.showProject;
    this.showCoreId = store.state.notification_settings.showCoreId;
    this.showSubCodes = store.state.notification_settings.showSubCodes;
    this.showFullMessage = store.state.notification_settings.showFullMessage;
    this.pendingNotifications = store.state.notification_settings.pendingNotifications;
    this.itemsPerPage = store.state.notification_settings.itemsPerPage;
    this.pagination.pageSize = parseInt(this.itemsPerPage)
    this.pagination.current = 1

    this.onShowCoreId()
    this.onShowFullMessage()
    this.onShowProject()
    this.onShowSubCodes(false)
    this.onLoadNotificationList();

    ModuleServices.getNotificationFilterCriteria()
      .then((response) => {
        this.notification_filter_criteria = response.data.data;
        for (const col of this.columns) {
          if (col.key in this.notification_filter_criteria) {
            col.filters = []
            for (const index in this.notification_filter_criteria[col.key]) {
              const item = this.notification_filter_criteria[col.key][index];
              if (item != null) {
                col.filters.push({
                  "text":item, "value":item
                });
              }
            }
            // sort filter criteria
            col.filters.sort(function(a, b){return a.value > b.value});
          }
        }
      })
      .catch((error) => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
      });

  },
  unmounted() {
    window.removeEventListener("resize", this.handlerResize);
  },
  methods: {
    onLoadNotificationList() {
      var filter = {};
      if (this.showCoreId && this.tableFilters.core_id && (this.tableFilters.core_id.length > 0))
        filter.core_id = {"$in": this.tableFilters.core_id};
      if (this.showProject && this.tableFilters.project && (this.tableFilters.project.length > 0))
        filter.project = {"$in": this.tableFilters.project};
      if (!this.showSubCodes)
        filter.sub_code = null;
      if (this.tableFilters.group && (this.tableFilters.group.length > 0))
        filter.group = {"$in": this.tableFilters.group};
      if (this.tableFilters.spec_number && (this.tableFilters.spec_number.length > 0))
        filter.spec_number = {"$in": this.tableFilters.spec_number};
      if (this.tableFilters.id && (this.tableFilters.id.length > 0))
        filter.id = {"$in": this.tableFilters.id};
      if (this.tableFilters.level && (this.tableFilters.level.length > 0))
        filter.level = {"$in": this.tableFilters.level};
      if (this.pendingNotifications)
        filter.time_end = null;
      var data = { "filter": filter, "pagination": this.pagination }
      if (this.groupNotifications) {
        data.groupNotifications = true;
      }
      
      var that = this;
      ModuleServices.putNotificationList(data)
        .then((response) => {

          that.notification_list = response.data.data;
          that.pagination.total = response.data.count;
          var new_selectedRowKeys = []
          for (const noti of that.notification_list) {
            if (that.selectedRowKeys.includes(noti._id)) {
              new_selectedRowKeys.push(noti._id);
            }
          }
          that.selectedRowKeys = new_selectedRowKeys;
          that.rowSelection.selectedRowKeys = that.selectedRowKeys;

        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.$router.push("/login");
          }
        });
    },
    handleTableChange(pag, filters, /*sorter*/) {
        this.tableFilters = filters
        this.pagination.current = pag.current;
        this.onLoadNotificationList();
    },
    handleChangePageSize(value) {
        store.state.notification_settings.itemsPerPage = value;
        this.pagination.pageSize = parseInt(value)
        this.pagination.current = 1
        this.onLoadNotificationList()
    },
    handlerResize() {
        // handler, after window has been resized
        // set this.scrolly to enable scrollin inside table to get fixed header and to make table as high as window
        this.height = this.$refs.notiList.clientHeight
        this.scrolly = this.height - 120
    },
    /* eslint-disable no-unused-vars */
    onTransformText(text, column, record, index) {
      try {
        if (column.key == 'time') {
            return new Date(record.time*1000).toLocaleString()
        } else if (column.key == 'time_end') {
            if (record.time_end == null) {
                return "-";
            } else {            
                return new Date(record.time_end*1000).toLocaleString();
            }
        } else if (column.key == 'id') {
          if (record.sub_id) 
            text += "." + record.sub_id;
          return text;
        } else if (column.key == 'spec_number') {
          return text + "  (" + record.source.replace("EsosModule", "") + ")"; 
        } else {
            return text;
        }
      } catch {
        return text;
      }
    },
    /* eslint-enable no-unused-vars */
    onShowSubCodes(reload) {
      for (const col of this.columns) {
        if (col.key == "sub_code") {
            if (this.showSubCodes)
                col.width = "8%"
            else
                col.width = "0"
        }
      }
      store.state.notification_settings.showSubCodes = this.showSubCodes
      if (reload)
        this.onLoadNotificationList()
    },
    /* eslint-enable no-unused-vars */
    onShowFullMessage() {
      for (const col of this.columns) {
        if (col.key == "message") {
            if (this.showFullMessage)
                col.ellipsis = false;
            else
                col.ellipsis = true;
        }
      }
      store.state.notification_settings.showFullMessage = this.showFullMessage
    },
    /* eslint-enable no-unused-vars */
    onShowCoreId() {
      for (const col of this.columns) {
        if (col.key == "core_id") {
            if (this.showCoreId)
                col.width = "5%"
            else
                col.width = "0"
        }
      }
      store.state.notification_settings.showCoreId = this.showCoreId
    },
    /* eslint-enable no-unused-vars */
    onShowProject() {
      for (const col of this.columns) {
        if (col.key == "project") {
            if (this.showProject)
                col.width = "8%"
            else
                col.width = "0"
        }
      }
      store.state.notification_settings.showProject = this.showProject;
    },
    onFilterGroup(/*value, record*/) {
      //console.log("Filter " + value + " - " + record);
      return true;
    },
    onReloadClick() {
      this.onLoadNotificationList();
    },
    onGroupNotificationsClick() {
      this.onLoadNotificationList();
    },
    onPendingNotificationsClick() {
      store.state.notification_settings.pendingNotifications = this.pendingNotifications;

      this.onLoadNotificationList();
    },
    handleMenuClick(event) {
      if (event.key == 1) {
        this.confirmState.comment = "";
        this.confirmState.confirm = false;
        this.visibleConfirm = true;
      }
    },
    handleConfirmOK() {
      this.visibleConfirm = false;
      var request = {
        comment: this.confirmState.comment,
        editor: this.confirmState.editor,
        confirm: this.confirmState.confirm,
        selectedMessages: this.selectedRowKeys
      }
      ModuleServices.putNotificationConfirm(request);
      this.onReloadClick();
    },
    getExpandIcon(props) {
      if (props.record.comments) {
        if (props.expanded) {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><MinusCircleOutlined /></a>
        } else {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><PlusCircleOutlined /></a>
        }
      } else 
        return null
    }


  },
};
</script>

<style scoped>
.ant-table-striped :deep(.table-pending) td {
  background-color: #ffb1b1a2;
}

</style>