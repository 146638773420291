ä<template>
<svg class="svg_with_border" 
  viewBox="0 0 141.5 151.5"
  xmlns="http://www.w3.org/2000/svg"
  :width="width" 
  :height="height" 
>

<path id="white_back" class="st0" d="M134,150.8H8.1c-3.9,0-7-3.1-7-7V7.9c0-3.9,3.1-7,7-7H134c3.9,0,7,3.1,7,7v135.9
	C141,147.7,137.8,150.8,134,150.8z"/>
<circle id="p_status" class="st2" cx="22" cy="41.1" r="3.5"/>
<text transform="matrix(1 0 0 1 46.5635 59.9764)" class="st2 st3 st4">{{t.PlantStatus}}</text>
<text transform="matrix(1 0 0 1 45.8476 45.869)" class="st3 st5">{{get_p_status_text()}}</text>
<path id="blue_back" class="st6" d="M141,20H1c0,0,0-4,0-8.8V9.6c0-4.9,2.2-8.8,8.1-8.8h123.8c5.9,0,8.1,4,8.1,8.8v1.5
	C141,16.1,141,20,141,20z"/>
<text transform="matrix(1 0 0 1 11.3232 14.7225)" class="st7 st3 st8">{{t.PlantStatus}}</text>

</svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    p: {
      type: Object
    },
    t: {
      type: Object
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    showThat() {
      console.log("Click");
    },
    get_p_status_text() {
      if (this.p.vPlantStatus) {
        return this.t.PlantStatusActive;
      } else {
        return this.t.PlantStatusInactive;
      }
    },
    get_p_status_color() {
      if (this.p.vPlantStatus) {
        return "#188C47";
      } else {
        return "#BF3C3C";
      }
    },
    get_p_usecases_vneid_visibility(active) {
      if (this.p.vUsecaseVndIdStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    },
    get_p_usecases_fcr_visibility(active) {
      if (this.p.vUsecaseFcrStatus == active) {
        return "";
      } else {
        return "hidden";
      }
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}

#p_status {
  fill: v-bind('get_p_status_color()') !important;
}

#p_vneid_false {
  visibility: v-bind('get_p_usecases_vneid_visibility(false)') !important;
}
#p_vneid_true {
  visibility: v-bind('get_p_usecases_vneid_visibility(true)') !important;
}
#p_fcr_false {
  visibility: v-bind('get_p_usecases_fcr_visibility(false)') !important;
}
#p_fcr_true {
  visibility: v-bind('get_p_usecases_fcr_visibility(true)') !important;
}

	.st0{fill:#FFFFFF;stroke:#969696;stroke-width:0.5;stroke-miterlimit:10;}
	.st1{fill:#E0E0E0;}
	.st2{fill:#939393;}
	.st3{font-family:'Verdana';}
	.st4{font-size:9px;}
	.st5{font-size:16px;}
	.st6{fill:#254965;}
	.st7{fill:#FFFFFF;}
	.st8{font-size:11px;}
  .active{fill:yellow;}
</style>