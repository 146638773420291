<template>
    <div class="edit-param-bool">
      <span style="font-weight:bold">{{ param.name }}</span> (Bool)  
        {{ getDescription(param) }}
        <Switch v-model:checked="param_temp.value" />
    </div>
</template>

<script>
import { Switch } from 'ant-design-vue';

export default {
  data() {
    return {
      param_temp: {},
    }
  },
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  components: { 
    Switch,
  },
  created() {
    this.param_temp = this.param;
  },
  methods:
  {
    getDescription(param) {
      if (param.description) {
        return " ( " + param.description + " )";
      } else {
        return ""
      }
    }
  }
}
</script>

<style scoped>
.edit-param-bool {
  padding: 2px;
  width: 100%;
  height: 100%;
  margin-bottom: 2px;
}

</style>